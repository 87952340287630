<template>
  <v-row>
    <v-col cols="12" md="9">
      <v-file-input
        v-model="file"
        :disabled="isDisabledInput"
        :label="label"
        show-size
        truncate-length="30" />
      <div v-if="contract">
        <document-item v-on="$listeners" :document="contract" />
      </div>
    </v-col>
    <v-col cols="12" md="3">
      <v-btn
        :disabled="isDisabledButton"
        :loading="isLoading"
        @click="upload()" class="mt-3">
        <v-icon color="primary">mdi-cloud-upload</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import api from '../../../../api/property';
import DocumentItem from './DocumentItem';
import { documentsFolder } from 'common/config';
import isEqual from 'lodash/isEqual';
import shortid from 'shortid'

export default {
  name: 'contract-upload',
  props: {
    label: { type: String, required: true },
    propertyId: { type: Number, default: null },
    subFolder: { type: String, default: null },
    value: { type: Object, default: null }
  },
  data() {
    return {
      contract: this.value,
      file: null,
      isLoading: false
    }
  },
  computed: {
    documentsFolderName() {
      return this.propertyId
        ? this.getPropertyFolder()
        : `${documentsFolder}/temp/${shortid.generate()}`;
    },
    isDisabledButton() {
      return !this.file || this.isLoading;
    },
    isDisabledInput() {
      return !!this.contract || this.isLoading;
    },
  },
  methods: {
    getPropertyFolder() {
      return this.subFolder
        ? `${documentsFolder}/${this.propertyId}/${this.subFolder}`
        : `${documentsFolder}/${this.propertyId}`;
    },
    async upload() {
      if (!this.file) return;
      this.isLoading = true;
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('folder', this.documentsFolderName);
      const data = await api.uploadDocument(formData);
      this.file = null;
      this.isLoading = false;
      this.$emit('uploaded', data);
    }
  },
  watch: {
    value() {
      if (!isEqual(this.value, this.contract)) this.contract = this.value;
    },
    photos() {
      this.$emit('input', this.photos);
    }
  },
  components: { DocumentItem }
}
</script>
