<template>
  <v-dialog
    @click:outside="close"
    @keydown.esc="close"
    v-model="show"
    max-width="900px"
    scrollable>
    <v-card class="pb-2">
      <v-card-title>
        <span class="headline">
          Povezane nekretnine
        </span>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-card-text>
        <div v-if="isLoading" class="d-flex justify-center mt-12 mb-12">
          <v-progress-circular :width="4" color="primary" indeterminate />
        </div>
        <template v-else>
          <div v-for="property in properties" :key="property.id" class="mt-3">
            <router-link
              :to="{ name: 'edit-property', params: { propertyId: property.id } }"
              target='_blank'>
              {{ property.title.HR }} (ID: {{ property.publicId }})
            </router-link>
          </div>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2 mb-2">
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)">
          Zatvori
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '../../api/client';

export default {
  name: 'client-properties-dialog',
  props: {
    clientId: { type: Number, default: null },
    value: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: false,
      properties: [],
      show: false
    };
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    async fetchProperties() {
      this.isLoading = true;
      this.properties = [];
      const properties = await api.fetchClientProperties(this.clientId);
      this.properties = properties;
      this.isLoading = false;
    }
  },
  created() {
    this.fetchProperties()
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.show = this.value;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-wrapper  {
  background-color: #ECEFF1;
}
</style>
