<template>
  <div class="property-card-wrapper mt-5">
    <v-chip v-if="property.featured"
      color="orange darken-2"
      label
      class="property-featured white--text">
      Istaknuto
    </v-chip>
    <v-hover v-slot="{ hover }">
      <v-card
        :class="{ 'on-hover': hover }"
        :color="property.published ? '#f0f4ec' : 'white'"
        :elevation="hover ? 8 : 2"
        :to="{ name: 'edit-property', params: { propertyId: property.id } }">
        <div class="d-flex flex-no-wrap">
          <v-chip class="ma-2 property-type" dark small color="red" label>
            {{ propertyTypeLabel }}
          </v-chip>
          <v-avatar v-if="image" class="ma-3" size="150" tile>
            <v-img :src="image"></v-img>
          </v-avatar>
          <v-avatar v-else class="ma-3" size="150" tile>
            <img width="150" src="../../assets/image-placeholder.jpg" />
          </v-avatar>
          <div class="main-info d-flex flex-column pt-1">
            <v-card-title
              v-text="property.title.HR"
              class="subtitle subtitle-1 pt-1 pb-0 px-1" />
            <v-row no-gutters class="pt-3 pr-3 pl-1 font-size-1">
              <v-col>
                <div>
                  Cijena: <strong>{{ propertyPrice }}</strong>
                </div>
                <div class="mt-1">
                  Lokacija: <strong>{{ locationGeneral }}</strong>
                  <v-icon
                    v-if="hasCoordinates"
                    color="green"
                    size="18"
                    class="ml-1 mb-1">
                    mdi-map-marker
                  </v-icon>
                </div>
              </v-col>
              <v-col class="text-right">
                <div class="grey--text text--darken-2">
                  ID: {{ property.publicId }}
                </div>
                <v-chip v-if="property.claim" small color="amber lighten-1" label>
                  Tražbina
                </v-chip>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mx-3" />
        <div class="font-size-1 px-3 pt-2">
          <v-row dense>
            <v-col>
              <div>Klijenti: {{ property.clients.length }}</div>
              <div :class="offersSentClass">
                Ponudeno: {{ sentOffers }}
              </div>
            </v-col>
            <v-col class="text-right">
              <div>

                Dodano: {{ new Date(property.createdAt) | relativeFormatDate }}
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      class="ml-1 mb-1"
                      color="primary"
                      size="16">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <div>Autor: {{ property.author.firstName }}</div>
                  <div>
                    Datum kreiranja: {{ new Date(property.createdAt) | formatDate }}
                  </div>
                  <div class="mt-4"><strong>Zadnje promjene</strong></div>
                  <div>
                    {{ new Date(property.updatedAt) | formatDate }}
                    ({{ property.editor.firstName }})
                  </div>
                </v-tooltip>
              </div>
              <div>
                Agent: 
                <span v-if="agentName" class="font-weight-bold">
                  {{ agentName }}
                </span>
                <span v-else class="font-weight-bold red--text text--darken-2">
                  N/A
                </span>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import filter from 'lodash/filter';
import get from 'lodash/get';
import propertyType from 'common/property/type';
import truncate from 'truncate';

export default {
  name: 'property-list-item',
  props: {
    property: { type: Object, required: true }
  },
  computed: {
    agentName() {
      const { agent } = this.property;
      if (!agent) return;
      const name = [];
      if (agent.firstName) name.push(agent.firstName);
      if (agent.lastName) name.push(agent.lastName);
      return name.join(' ');
    },
    hasCoordinates() {
      return !!this.property.coordinates &&
        !!this.property.coordinates.lat &&
        !!this.property.coordinates.lng;
    },
    image() {
      return get(this.property.photos, '[0].sizes.SM.location')
    },
    locationGeneral() {
      const location = [];
      const { property } = this;
      if (property.island && property.island.name !== 'OSTALO') {
        location.push(`Otok ${property.island.name}`);
      } else if (property.region) {
        location.push(property.region.name);
      }
      if (property.municipality && !property.city) {
        location.push(property.municipality.name);
      }
      if (property.city) location.push(property.city.name);
      return !location.length ? 'N/A' : location.join(', ');
    },
    offersSentClass() {
      if (!this.sentOffers) return 'red--text';
      return this.property.clients.length > this.sentOffers
        ? 'red--text' : 'green--text'
    },
    propertyPrice() {
      if (!this.property.price) return 'Na upit';
      const price = `${this.$options.filters.formatNumber(this.property.price)} €`;
      return (this.property.price && this.property.priceOnRequest)
        ? `${price} (Na upit)`
        : price;
    },
    propertyTypeLabel() {
      return truncate(propertyType[this.property.propertyType].label.HR, 20);
    },
    sentOffers() {
      const messageIds = filter(this.property.clients, ({ emailMessages}) => {
        return emailMessages.length;
      });
      return messageIds.length;
    }
  }
}
</script>

<style lang="scss">
.property-card-wrapper {
  position: relative;
}

.main-info {
  position: relative;
  flex: 1;
}

.subtitle {
  word-break: normal !important;
}

.green-color {
  color: green;
}

.red-color {
  color: red;
}

.on-hover {
  cursor: pointer;
}

.v-chip.item-type {
  position: absolute;
  bottom: 0.5rem;
  right: 0.25rem;
}

.v-chip.property-type {
  position: absolute;
  z-index: 1;
  top: 0.75rem;
  left: -0.125rem;
}

.v-chip.property-featured {
  position: absolute;
  top: -1.75rem;
  right: 0.5rem;
}
</style>
