<template>
  <div>
    <v-alert border="top" colored-border type="info" elevation="2">
      Maksimalna veličina jedne datoteke može iznositi {{ maxSize }}MB.<br>
      Dozvoljene ekstenzije:
      <span class="text-body-2">.{{ allowed.extensions.split(',').join(', .') }}</span>
    </v-alert>
    <v-fade-transition
      v-for="(file, index) in filesOnHold"
      :key="file.id">
      <div>
        <span>{{ file.name }}</span> -
        <span>{{ file.size | formatSize }}</span>
        <v-icon
          v-if="!file.active && !file.success"
          @click="remove(index)"
          class="ml-2 mb-1"
          color="red"
          size="18">
          mdi-trash-can-outline
        </v-icon>
        <v-icon
          v-if="!file.active && file.success"
          class="ml-2 mb-1"
          color="success"
          size="18">
          mdi-check-circle
        </v-icon>
        <v-progress-circular
          v-if="file.active"
          class="ml-3"
          color="primary"
          indeterminate
          size="14"
          width="2" />
        <span
          v-if="file.error && file.error === 'size'"
          class="upload-error ml-2 text-body-2">
          Greška: Datoteka je prevelika!
        </span>
        <span v-else-if="file.error" class="upload-error ml-2 text-body-2">
          Greška: {{ file.error }}!
        </span>
      </div>
    </v-fade-transition>
    <div>
      <div
        v-if="$refs.upload"
        :class="dropContainerClass"
        class="drop-container d-flex justify-center align-center">
        <div class="text-center">
          <h3>{{ label }}</h3>
          <v-icon class="mr-2 mb-1">mdi-cloud-upload</v-icon>
          <v-icon class="ml-2 mb-1">mdi-cloud-upload</v-icon>
        </div>
      </div>
      <div :class="`flex-${buttonsDirection}`" class="d-flex justify-space-between">
        <file-upload
          ref="upload"
          v-model="files"
          @input-file="inputFile"
          @input-filter="inputFilter"
          :accept="allowed.mimeType"
          :data="{ folder: folderName }"
          :drop="drop"
          :extensions="allowed.extensions"
          :headers="headers"
          :input-id="inputId"
          :multiple="multiple"
          :post-action="postAction"
          :size="1024 * 1024 * maxSize"
          class="select-button pa-1">
          <v-btn class="mt-2">
            <v-icon left>mdi-plus</v-icon> Odaberi datoteke
          </v-btn>
        </file-upload>
        <v-btn
          @click.prevent="$refs.upload.active=true"
          :disabled="isUploadDisabled"
          :loading="isUploading"
          class="mt-2"
          color="primary">
          Upload
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
import every from 'lodash/every';
import filter from 'lodash/filter';
import { documentsFolder, photosFolder } from 'common/config';
import shortid from 'shortid'
import some from 'lodash/some';

const extensions = {
  IMAGES: 'gif,jpg,jpeg,png,webp',
  DOCUMENTS: 'pdf,rtf,ppt,doc,docx,xls,xlsx'
}
const mimeType = {
  IMAGES: 'image/png,image/gif,image/jpeg,image/webp',
  DOCUMENTS: 'application/pdf,application/rtf,application/vnd.ms-powerpoint,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export default {
  name: 'file-upload-wrapper',
  props: {
    accept: { type: String, default: 'IMAGES' },
    drop: { type: Boolean, default: true },
    inputId: { type: String, default: 'file' },
    label: { type: String, required: true },
    maxSize: { type: Number, default: 5 },
    multiple: { type: Boolean, default: true },
    propertyId: { type: Number, default: null }
  },
  data: function () {
    return {
      files: []
    }
  },
  computed: {
    allowed() {
      return this.accept === 'IMAGES'
        ? { mimeType: mimeType.IMAGES, extensions: extensions.IMAGES }
        : { mimeType: mimeType.DOCUMENTS, extensions: extensions.DOCUMENTS }
    },
    buttonsDirection() {
      return this.$vuetify.breakpoint.name === 'xs' ? 'column' : 'row';
    },
    dropContainerClass() {
      return {
        'is-active': this.$refs.upload.dropActive,
        'is-accent': this.accept === 'IMAGES'
        };
    },
    filesOnHold() {
      return filter(this.files, { success: false })
    },
    folderName() {
      const baseFolder = this.accept === 'IMAGES' ? photosFolder : documentsFolder;
      return this.propertyId
        ? `${baseFolder}/${this.propertyId}`
        : `${baseFolder}/temp/${shortid.generate()}`;
    },
    headers() {
      return { 'authorization': `Bearer ${localStorage.getItem('token')}` };
    },
    isAllUploaded() {
      return every(this.files, { success: true });
    },
    isUploadDisabled() {
      return this.isAllUploaded || this.isUploading;
    },
    isUploading() {
      return some(this.files, { active: true });
    },
    postAction() {
      return this.accept === 'IMAGES'
        ? '/api/property/upload-photo'
        : '/api/property/upload-document'
    },
    // Used in external components
    showWarning() {
      return !this.isAllUploaded || this.isUploading;
    }
  },
  filters: {
    formatSize: size => {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB'
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB'
      }
      return size.toString() + ' B'
    }
  },
  methods: {
    inputFile: function (newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.xhr && newFile.xhr.status === 200) {
          this.$emit('uploaded', newFile.response);
        }
      }
    },
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (this.accept === 'IMAGES') {
          if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
            return prevent()
          }
        }
        else if (!/\.(pdf|rtf|ppt|doc|docx|xls|xlsx)$/i.test(newFile.name)) {
          return prevent()
        }
      }
      // Create a blob field
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }
    },
    remove(index) {
      this.files.splice(index, 1);
    }
  },
  watch: {
    showWarning(val) {
      this.$emit('showWarning', val);
    }
  },
  components: { FileUpload }
}
</script>

<style lang="scss" scoped>
.drop-container {
  background: #eaeef4;
  padding: 1rem 0;
  border: 2px solid transparent;

  &.is-active {
    padding: 5rem 0;
    border: 2px solid #2196f3;
  }

  &.is-accent {
    background: #ebf4ea;
  }
}

.select-button {
  margin-left: -2px;
}

.upload-error {
  background: #FFEBEE;
  padding: 0.25rem;
}

::v-deep {
  .file-uploads {
    display: inline !important;

    label {
      cursor: pointer !important;
    }
  }
}
</style>
