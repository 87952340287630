<template>
  <v-list nav dense>
    <v-list-item
      v-for="item in flattenedMenuItems"
      :key="item.label"
      :to="getRoute(item)"
      :class="{ 'menu-category': item.isParent }"
      exact
      link
      color="primary">
      <v-list-item-title :class="{ 'pl-4': (!item.isParent && !item.isHome) }">
        {{ item.label }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item link @click="$emit('logout')">
      <v-list-item-title>
        Odjava ({{ user }})
      </v-list-item-title>
      <v-icon class="mr-2">mdi-account-outline</v-icon>
    </v-list-item>
  </v-list>
</template>

<script>
import { flatten, map, omit } from 'lodash';

export default {
  name: 'navigation-links',
  props: {
    menuItems: { type: Array, required: true },
    user: { type: String, default: null }
  },
  computed: {
    flattenedMenuItems() {
      const mapped = map(this.menuItems, item => {
        return [omit(item, 'children'), ...(item.children || [])];
      });
      return flatten(mapped);
    }
  },
  methods: {
    getRoute(item) {
      if (item.isParent || !item.routerName) return null;
      return { name: item.routerName };
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-category {
  cursor: default;

  .v-list-item__title {
    color: gray;
  }
}
</style>
