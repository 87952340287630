import _get from 'lodash/get';
import request from './request';

const BASE_URL = '/partner';

const url = {
  partner: partnerId => `${BASE_URL}/${partnerId}`,
};

const getData = res => _get(res, 'data', {});

function create(partner) {
  return request.post(BASE_URL, partner).then(getData);
}

function fetch(excludedIds) {
  const params = { excludedIds };
  return request.get(BASE_URL, { params }).then(getData);
}

function get(id) {
  return request.get(url.partner(id)).then(getData);
}

function update(partner) {
  return request.patch(`${BASE_URL}/${partner.id}`, partner).then(getData);
}

export default {
  create,
  fetch,
  get,
  update
};
