<template>
  <v-menu
    v-model="isOpen"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :disabled="disabled"
        :label="placeholder"
        :rules="required ? [v => !!v || 'Ovo polje je obavezno'] : []"
        autocomplete="off"
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on" />
    </template>
    <v-date-picker v-model="date" @input="update" />
  </v-menu>
</template>

<script>
export default {
  name: 'datepicker',
  props: {
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Datum' },
    required: { type: Boolean, default: false },
    value: { type: String, default: null }
  },
  data() {
    return {
      isOpen: false,
      date: this.value
    };
  },
  methods: {
    update(value) {
      this.isOpen = false;
      this.$emit('input', value);
    }
  }
}
</script>
