<template>
  <div>
    <iframe
      :src="videoEmbedUrl"
      :width="width"
      height="300"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen>
    </iframe>
  </div>
</template>

<script>
const getYoutubeVideoId = url => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match&&match[7].length==11)? match[7] : false;
}

export default {
  name: 'video-player',
  props: {
    videoUrl: { type: String, required: true }
  },
  computed: {
    isScreenXs() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    videoEmbedUrl() {
      if (!this.videoUrl) return;
      const id = getYoutubeVideoId(this.videoUrl);
      return `https://www.youtube.com/embed/${id}`;
    },
    width() {
      return this.isScreenXs ? '100%' : '400';
    }
  }
}
</script>
