<template>
  <v-row>
    <v-col cols="12" md="9">
      <v-file-input
        v-model="file"
        :disabled="isDisabledInput"
        :label="label"
        show-size
        truncate-length="30" />
      <div v-if="coverPhoto">
        <v-card class="mt-2 mb-2" flat>
          <delete-modal
            @confirm="$emit('delete')"
            subtitle="Jeste li sigurni da želite obrisati ovu fotografiju?"
            title="Obriši fotografiju" />
          <v-img :src="coverPhoto.sizes.SM.location" aspect-ratio="1" width="100" />
        </v-card>
      </div>
    </v-col>
    <v-col cols="12" md="3">
      <v-btn
        :disabled="isDisabledButton"
        :loading="isLoading"
        @click="upload()"
        class="mt-3">
        <v-icon color="primary">mdi-cloud-upload</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import api from '../../api/content';
import DeleteModal from '../common/DeleteModal';
import { contentPhotosFolder } from 'common/config';
import isEqual from 'lodash/isEqual';

export default {
  name: 'cover-photo-upload',
  props: {
    label: { type: String, required: true },
    contentId: { type: Number, default: null },
    value: { type: Object, default: null }
  },
  data() {
    return {
      coverPhoto: this.value,
      file: null,
      isLoading: false
    }
  },
  computed: {
    coverPhotosFolderName() {
      return `${contentPhotosFolder}/${this.contentId}`;
    },
    isDisabledButton() {
      return !this.file || this.isLoading;
    },
    isDisabledInput() {
      return !!this.profilePhoto || this.isLoading;
    },
  },
  methods: {
    async upload() {
      if (!this.file) return;
      this.isLoading = true;
      const formData = new FormData()
      formData.append('upload', this.file)
      formData.append('folder', this.coverPhotosFolderName);
      formData.append('isCover', true);
      const data = await api.uploadPhoto(formData);
      this.file = null;
      this.isLoading = false;
      this.$emit('uploaded', data);
    }
  },
  watch: {
    value() {
      if (!isEqual(this.value, this.coverPhoto)) this.coverPhoto = this.value;
    },
    photos() {
      this.$emit('input', this.photos);
    }
  },
  components: { DeleteModal }
}
</script>

<style lang="scss" scoped>
::v-deep .delete-icon {
  position: absolute !important;
  top: 0.25rem;
  left: 0.25rem;
  color: #B71C1C !important;
  border-radius: 50% !important;
  z-index: 1;
  cursor: pointer;
  background: #fff;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}
</style>
