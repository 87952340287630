import api from '../../../api/agent';

const create = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.create(data).then(agent => {
    commit('setAgent', agent);
    commit('setLoadingFlag', false);
    return agent;
  });
};

const fetch = ({ commit }, excludedIds) => {
  commit('setLoadingFlag', true);
  return api.fetch(excludedIds).then(agents => {
    commit('setAgents', agents);
    commit('setLoadingFlag', false);
    return agents;
  });
};

const get = ({ commit }, id) => {
  return api.get(id).then(agent => {
    commit('setAgent', agent);
    return agent;
  });
};

const update = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.update(data).then(agent => {
    commit('setAgent', agent);
    commit('setLoadingFlag', false);
    return agent;
  });
};

export { create, fetch, get, update };
