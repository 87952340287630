<template>
  <v-tooltip v-model="show" top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon class="mb-1">
        <v-icon :color="color">mdi-help-circle</v-icon>
      </v-btn>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'info-tooltip',
  props: {
    color: { type: String, default: 'primary ligthen-3' },
    text: { type: String, required: true }
  },
  data () {
    return {
      show: false,
    }
  }
}
</script>
