
const setLoadingFlag = (state, flag) => (state.isLoading = flag);

const setPartner = (state, partner) => {
  const index = state.items.map(item => item.id).indexOf(partner.id);
  if (index !== -1) state.items.splice(index, 1);
  state.items.push(partner);
};

const setPartners = (state, partners) => {
  state.items = partners;
};

export {
  setPartner, setPartners, setLoadingFlag
};
