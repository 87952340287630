<template>
  <v-dialog
    @click:outside="close"
    @keydown.esc="close"
    v-model="show"
    max-width="900px"
    scrollable>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-card class="pb-2">
        <div class="d-flex justify-end pr-3 pt-3">
          <v-icon @click="close" class="close-icon" size="30">
            mdi-close-circle-outline
          </v-icon>
        </div>
        <div v-if="isLoading" class="d-flex justify-center my-12">
          <v-progress-circular :width="4" color="primary" indeterminate />
        </div>
        <template v-else>
          <v-card-title>
            <span class="headline">Novi blog post</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-card class="px-4 pb-4 mb-2 mt-4 mx-4">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="contentData.title.HR"
                    label="Naslov (na hrvatskom jeziku)" />
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mt-2 mb-2">
            <v-spacer></v-spacer>
            <v-btn @click="$emit('input', false)">
              Poništi
            </v-btn>
            <v-btn @click="save" :disabled="!valid" color="success">
              Dodaj novi blog post
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import contentType from 'common/content/type';

export default {
  name: 'blog-post-dialog',
  props: {
    value: { type: Boolean, default: false }
  },
  data() {
    return {
      contentData: {
        title: { HR: null },
        type: contentType.BLOG
      },
      isLoading: false,
      show: false,
      valid: true
    };
  },
  methods: {
    ...mapActions('blog', ['create']),
    close() {
      this.$emit('input', false);
    },
    async save() {
      this.$refs.form.validate();
      this.$nextTick(async () => {
        if (!this.valid) return;
        await this.create(this.contentData);
        this.$emit('input', false)
      });
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.show = this.value;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-wrapper  {
  background-color: #ECEFF1;
}
</style>
