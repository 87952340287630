import _get from 'lodash/get';
import request from './request';

const BASE_URL = '/agent';

const url = {
  agent: agentId => `${BASE_URL}/${agentId}`,
  uploadProfilePhoto: `${BASE_URL}/upload-profile-photo`
};

const getData = res => _get(res, 'data', {});

function create(agent) {
  return request.post(BASE_URL, agent).then(getData);
}

function fetch() {
  return request.get(BASE_URL).then(getData);
}

function get(id) {
  return request.get(url.agent(id)).then(getData);
}

function update(agent) {
  return request.patch(`${BASE_URL}/${agent.id}`, agent).then(getData);
}

function uploadProfilePhoto(data) {
  return request.post(url.uploadProfilePhoto, data).then(getData);
}

export default {
  create,
  fetch,
  get,
  update,
  uploadProfilePhoto
};
