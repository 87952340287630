const BAD = {
  key: 'bad',
  value: 'BAD',
  label: { EN: 'Bad', HR: 'Loše' },
  sortValue: 3
};
const EXCELLENT = {
  key: 'excellent',
  value: 'EXCELLENT',
  label: { EN: 'Excellent', HR: 'Izvrsno' },
  sortValue: 1
};
const FOR_DEMOLITION = {
  key: 'forDemolition',
  value: 'FOR_DEMOLITION',
  label: { EN: 'For demolition', HR: 'Za rušenje' },
  sortValue: 5
};
const FOR_RECONSTRUCTION = {
  key: 'forReconstruction',
  value: 'FOR_RECONSTRUCTION',
  label: { EN: 'For reconstruction', HR: 'Za rekonstrukciju' },
  sortValue: 4
};
const GOOD = {
  key: 'good',
  value: 'GOOD',
  label: { EN: 'Good', HR: 'Dobro' },
  sortValue: 2
};

module.exports = { BAD, EXCELLENT, FOR_DEMOLITION, FOR_RECONSTRUCTION, GOOD };
