var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-header',[_c('h1',[_vm._v("Klijenti")])]),_c('v-container',[_c('div',{staticClass:"client-wrapper pa-4 mt-4"},[(_vm.showClientDialog)?_c('client-dialog',{attrs:{"clientId":_vm.clientId},on:{"saved":_vm.onSave},model:{value:(_vm.showClientDialog),callback:function ($$v) {_vm.showClientDialog=$$v},expression:"showClientDialog"}}):_vm._e(),(_vm.showPropertiesDialog)?_c('properties-dialog',{attrs:{"clientId":_vm.viewingClientId},model:{value:(_vm.showPropertiesDialog),callback:function ($$v) {_vm.showPropertiesDialog=$$v},expression:"showPropertiesDialog"}}):_vm._e(),_c('v-btn',{staticClass:"mt-3 mb-3",attrs:{"color":"primary","small":"","tile":""},on:{"click":function($event){_vm.showClientDialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Novi klijent ")],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{ itemsPerPageOptions: [10, 20, 50, 100] },"options":_vm.options,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalCount,"loading":_vm.isLoading,"items-per-page":20,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"search-field mx-4 pt-6",attrs:{"append-icon":"mdi-magnify","label":"Pronađi klijenta"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [[_vm._v(" "+_vm._s(item.fullName)+" "),_c('v-tooltip',{attrs:{"max-width":"300","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"color":"primary","size":"16"}},on),[_vm._v(" mdi-card-account-mail-outline ")])]}}],null,true)},[_c('div',[_vm._v("Email: "+_vm._s(item.email))]),(item.phone)?_c('div',[_vm._v("Mobitel: "+_vm._s(item.phone))]):_vm._e()])]]}},{key:"item.propertyPreference.budget",fn:function(ref){
var item = ref.item;
return [(item.propertyPreference.budget)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.propertyPreference.budget))+" ")]):_vm._e()]}},{key:"item.properties.length",fn:function(ref){
var item = ref.item;
return [(_vm.hasProperties(item.properties))?_c('a',{staticClass:"properties-count",on:{"click":function($event){return _vm.openClientPropertiesDialog(item.id)}}},[_vm._v(" "+_vm._s(item.properties.length)+" ")]):_c('span',{staticClass:"red--text text--darken-2"},[_vm._v("0")])]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [(_vm.hasLongNote(item.note))?[_vm._v(" "+_vm._s(item.note.substring(0, 60))+"... "),_c('v-tooltip',{attrs:{"max-width":"300","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","size":"16"}},on),[_vm._v(" mdi-tooltip-text-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.note))])])]:_c('span',[_vm._v(_vm._s(item.note))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(new Date(item.createdAt))))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"action-icon",attrs:{"color":"blue darken-4","small":""},on:{"click":function($event){return _vm.goTo(item.id)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }