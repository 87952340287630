<template>
  <div>
    <iframe
      :src="tourUrl" 
      :height="height"
      :width="width"
      frameborder="0"
      allowvr="yes"
      allow="vr; xr; accelerometer; magnetometer; gyroscope; autoplay;"
      allowfullscreen 
      mozallowfullscreen="true" 
      webkitallowfullscreen="true">
    </iframe> 
  </div>
</template>

<script>
export default {
  name: 'virtual-tour',
  props: {
    tourUrl: { type: String, required: true }
  },
  computed: {
    isScreenXs() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    width() {
      return this.isScreenXs ? '100%' : '800';
    },
    height() {
      return this.isScreenXs ? '300' : '600';
    }
  }
}
</script>
