<template>
  <div>
    <div v-for="(document, index) in documents" :key="document.location">
      <document-item
        @delete="remove($event)"
        :document="document"
        :index="index" />
    </div>
  </div>
</template>

<script>
import DocumentItem from './DocumentItem';
import isEqual from 'lodash/isEqual';

export default {
  name: 'documents',
  props: {
    value: { type: Array, default: () => [] }
  },
  data() {
    return {
      documents: this.value
    }
  },
  methods: {
    remove(index) {
      this.documents.splice(index, 1);
    }
  },
  watch: {
    value() {
      if (!isEqual(this.value, this.documents)) this.documents = this.value;
    },
    documents() {
      this.$emit('input', this.documents);
    }
  },
  components: { DocumentItem }
}
</script>
