<template>
  <div>
    <add-client-dialog
      v-if="showAddClientsDialog"
      v-model="showAddClientsDialog"
      :property="property" />
    <contracts-dialog
      v-if="editingPropertyClient"
      v-model="editingPropertyClient"
      :propertyClient="editingPropertyClient" />
    <access-token-dialog
      v-if="editingPropertyClientToken"
      v-model="editingPropertyClientToken"
      :propertyClient="editingPropertyClientToken" />
    <email-offer-dialog
      v-if="sendingOfferPropertyClient"
      v-model="sendingOfferPropertyClient"
      :getTokenLink="getTokenLink"
      :property="property"
      :propertyClient="sendingOfferPropertyClient" />
    <sent-offers-dialog
      v-if="fetchingMessagesPropertyClientId"
      v-model="fetchingMessagesPropertyClientId"
      :propertyId="property.id"
      :propertyClientId="fetchingMessagesPropertyClientId" />
    <v-btn
      @click="showAddClientsDialog = true"
      class="mt-3 mb-3"
      color="primary"
      small
      tile>
      <v-icon left>mdi-plus</v-icon>
      Poveži klijenta
    </v-btn>
    <v-data-table
      :footer-props="{ itemsPerPageOptions: [10, 20, 50, 100, -1] }"
      :headers="headers"
      :items="property.clients"
      :items-per-page="50"
      :sort-by="'id'"
      :sort-desc="true"
      class="elevation-1">
      <template v-slot:[`item.offerSent`]="{ item }">
        <div v-if="item.emailMessages.length">
          DA
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                @click="fetchingMessagesPropertyClientId = item.id"
                class="ml-2 mb-1"
                color="primary"
                size="16">
                mdi-open-in-new
              </v-icon>
            </template>
            <span>Više detalja</span>
          </v-tooltip>
        </div>
        <span v-else>NE</span>
      </template>
      <template v-slot:[`item.documents`]="{ item }">
        <div class="contract-list">
          <document-item
            v-for="contract in getContracts(item)"
            :key="contract.key"
            :allowDelete="false"
            :document="item.documents[contract.key]"
            :documentName="contract.label" />
        </div>
      </template>
      <template v-slot:[`item.link`]="{ item }">
        <div v-if="!isEmpty(item.accessTokens) && !hasExpired(item)">
          <a :href="getTokenLink(item, true)" target="_blank">Link</a>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                @click="openAccessTokenDialog(item)"
                class="ml-2"
                size="18">
                mdi-pencil
              </v-icon>
            </template>
            <span>Uredi pristupni link</span>
          </v-tooltip>
          <v-tooltip v-if="property" top>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                @click="copyText(item)"
                class="ml-2"
                color="primary"
                size="14">
                mdi-content-copy
              </v-icon>
            </template>
            <span>Kopiraj link</span>
          </v-tooltip>
          <span class="copy-info">
            <v-fade-transition>
              <span
                v-if="copiedToken === getToken(item)"
                class="ml-2 orange--text text--darken-2">
                Link kopiran!
              </span>
            </v-fade-transition>
          </span>
          <div class="caption">
            Vrijedi do: {{ new Date(item.accessTokens[0].expiresAt) | formatDate }}
          </div>
        </div>
        <div v-else-if="!isEmpty(item.accessTokens) && hasExpired(item)">
          <span class="has-expired">
            Istekao {{ new Date(item.accessTokens[0].expiresAt) | formatDate }}
          </span>
        </div>
        <span v-else>- -</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip v-if="isEmpty(item.accessTokens) || hasExpired(item)" top>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="openAccessTokenDialog(item)"
              class="mr-4"
              size="18">
              mdi-link-variant-plus
            </v-icon>
          </template>
          <span>Generiraj novi pristupni link</span>
        </v-tooltip>
        <v-tooltip v-else top>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="removeLink(item.id, item.accessTokens[0].token)"
              class="mr-4"
              color="red"
              size="18">
              mdi-link-variant-minus
            </v-icon>
          </template>
          <span>Deaktiviraj link</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="editingPropertyClient = item"
              class="mr-4"
              color="primary"
              size="18">
              mdi-file-upload-outline
            </v-icon>
          </template>
          <span>Uredi dokumente i ugovore</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="sendingOfferPropertyClient = item"
              :disabled="isSendingDisabled(item)"
              class="mr-4"
              color="green"
              size="18">
              mdi-email-outline
            </v-icon>
          </template>
          <span>Pošalji ponudu na email</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="deletingPropertyClientId = item.id"
              class="mr-4"
              color="red darken-3"
              size="18">
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>Ukloni klijenta</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <div class="hidden-input" id="clipboardLink">{{ clipboardLink }}</div>
    <delete-modal
      @confirm="remove"
      @cancel="deletingPropertyClientId = null"
      :show="!!deletingPropertyClientId"
      hideDeleteIcon
      subtitle="Jeste li sigurni da želite ukloniti ovog klijenta?"
      title="Ukloni klijenta" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AccessTokenDialog from './AccessTokenDialog';
import AddClientDialog from './AddClientDialog';
import ContractsDialog from './ContractsDialog';
import DeleteModal from '../../../common/DeleteModal';
import DocumentItem from '../documents/DocumentItem'
import EmailOfferDialog from './EmailOfferDialog';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { hasExpired } from 'common/util/dates';
import SentOffersDialog from './SentOffersDialog';

const contracts = [
  { key: 'contactAgreement', label: 'Ugovor o ostvarenom kontaktu' },
  { key: 'NDA', label: 'NDA' },
  { key: 'mediationAgreement', label: 'Ugovor o posredovanju (UOP)' },
  { key: 'proofOfFunds', label: 'Proof of funds' }
];
const headers = [
  { text: 'Ime', sortable: true, value: 'client.fullName' },
  { text: 'Ponuda poslana', value: 'offerSent' },
  { text: 'Ugovori', sortable: false, value: 'documents' },
  { text: 'Pristupni Link', sortable: false, value: 'link' },
  { text: 'Akcija', sortable: false, value: 'action' },
];

export default {
  name: 'clients-section',
  props: {
    propertyId: { type: Number, default: null }
  },
  data: () => ({
    deletingPropertyClientId: null,
    editingPropertyClient: null,
    editingPropertyClientToken: null,
    clipboardLink: '',
    copiedToken: '',
    fetchingMessagesPropertyClientId: null,
    sendingOfferPropertyClient: null,
    showAddClientsDialog: false,
  }),
  computed: {
    ...mapState('properties', ['property']),
    headers() {
      return headers;
    }
  },
  methods: {
    ...mapActions('properties', ['removeAccessToken', 'removePropertyClient']),
    copyText (item) {
      var range = document.createRange();
      const link = this.getTokenLink(item);
      this.clipboardLink = `${window.location.origin}${link}`;
      const containerNode = document.getElementById('clipboardLink');
      this.$nextTick(async () => {
        range.selectNode(containerNode);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        this.copiedToken = this.getToken(item);
        setTimeout(() => this.copiedToken = null, 1500);
      });
    },
    getContracts(propertyClient) {
      return filter(contracts, ({ key }) => get(propertyClient.documents, key));
    },
    get: get,
    getToken({ accessTokens }) {
      return accessTokens[0].token;
    },
    getTokenLink({ accessTokens }, includeHref) {
      // TODO: Replace with client's preferred language
      const link = `${process.env.PORTAL_URL}/hr/property/${this.propertyId}/token/${accessTokens[0].token}`;
      return includeHref ? `${link}?ref=mls-link` : link;
    },
    hasExpired(item) {
      return hasExpired(new Date(item.accessTokens[0].expiresAt));
    },
    isEmpty,
    isSendingDisabled(item) {
      return isEmpty(item.accessTokens) || this.hasExpired(item);
    },
    async openAccessTokenDialog(propertyClient) {
      this.editingPropertyClientToken = propertyClient;
    },
    async remove() {
      const { propertyId, deletingPropertyClientId } = this;
      const data = { propertyId, propertyClientId: deletingPropertyClientId }
      await this.removePropertyClient(data);
      this.deletingPropertyClientId = null;
    },
    async removeLink(propertyClientId, token) {
      this.removeAccessToken({ propertyClientId, token });
    }
  },
  components: {
    AccessTokenDialog,
    AddClientDialog,
    ContractsDialog,
    DeleteModal,
    DocumentItem,
    EmailOfferDialog,
    SentOffersDialog
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-item-group.v-tabs-bar {
  background-color: transparent !important;
}

.v-tab.accent-tab {
  &, .v-icon {
    color: #E65100 !important;
  }
}

.hidden-input {
  color: #fff;
  height: 1px;
  font-size: 1px;
}

.copy-info {
  display: inline-block;
  width: 100px;
}

.has-expired {
  color: #E65100;
}

.contract-list {
  font-size: 0.75rem;
}

.more-details {
  font-size: 0.85rem;
  cursor: pointer;
}
</style>
