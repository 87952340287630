<template>
  <div>
    <a :href="document.location" target="_blank">
      <span>{{ name }}</span>
    </a>
    <delete-modal
      v-if="allowDelete"
      @confirm="$emit('delete', index)"
      icon="trash-can-outline"
      :iconSize="18"
      subtitle="Jeste li sigurni da želite obrisati ovaj dokument?"
      title="Obriši dokument" />
  </div>
</template>

<script>
import DeleteModal from '../../../common/DeleteModal';

export default {
  name: 'document-item',
  props: {
    allowDelete: { type: Boolean, default: true },
    document: { type: Object, required: true },
    index: { type: Number, default: 0 },
    documentName: { type: String, default: null }
  },
  computed: {
    name() {
      return this.documentName || this.document.originalFileName;
    }
  },
  components: { DeleteModal }
}
</script>

<style lang="scss" scoped>
::v-deep .delete-icon {
  color: #B71C1C !important;
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
}
</style>
