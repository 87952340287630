module.exports = {
  LOW: {
    key: 'low',
    value: 'LOW',
    label: { EN: 'Low', HR: 'Nizak' }
  },
  MEDIUM: {
    key: 'medium',
    value: 'MEDIUM',
    label: { EN: 'Medium', HR: 'Srednji' }
  },
  HIGH: {
    key: 'high',
    value: 'HIGH',
    label: { EN: 'High', HR: 'Visok' }
  }
};
