<template>
  <div>
    <app-header>
      <h1>Agenti</h1>
    </app-header>
    <v-container>
      <div class="agent-wrapper pa-4 mt-4">
        <agent-dialog v-if="showDialog" v-model="showDialog" :agentId="agentId" />
        <v-btn
          @click="showDialog = true"
          class="mt-3 mb-3"
          color="primary"
          small
          tile>
          <v-icon left>mdi-plus</v-icon>
          Novi agent
        </v-btn>
        <v-data-table
          :footer-props="{ itemsPerPageOptions: [10, 20, 50, 100, -1] }"
          :headers="headers"
          :items="agents"
          :items-per-page="50"
          :search="search"
          class="elevation-1">
          <template v-slot:top>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pronađi agenta"
              class="search-field mx-4 pt-6" />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              @click="goTo(item.id)"
              class="action-icon"
              color="blue darken-4"
              small>
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import AppHeader from '../common/Header';
import AgentDialog from './AgentDialog';
import { mapActions, mapGetters } from 'vuex'

const headers = [
  { text: 'Ime', sortable: true, value: 'firstName' },
  { text: 'Prezime', sortable: true, value: 'lastName' },
  { text: 'Email', value: 'email' },
  { text: 'Mobitel', sortable: false, value: 'phone' },
  { text: 'Vezanih nekretnina', value: 'properties.length' },
  { text: 'Akcija', sortable: false, value: 'action' },
];

export default {
  name: 'agents-page',
  props: {
    agentId: { type: Number, default: null }
  },
  data: () => ({
    search: '',
    showDialog: false
  }),
  computed: {
    ...mapGetters('agents', ['agents']),
    headers() {
      return headers;
    },
    isEditing() {
      return !!this.$route.params.agentId;
    }
  },
  methods: {
    ...mapActions('agents', ['fetch']),
    goTo(agentId) {
      this.$router.push({ name: 'edit-agent', params: { agentId } });
    }
  },
  created() {
    this.fetch();
  },
  watch: {
    isEditing: {
      immediate: true,
      handler() {
        this.showDialog = this.isEditing;
      }
    },
    showDialog(val) {
      if (!val && this.$route.name === 'edit-agent') {
        this.$router.push({ name: 'agents' });
      }
    }
  },
  components: { AppHeader, AgentDialog }

}
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 400px;
}

.agent-wrapper {
  background: #fff;
}
</style>
