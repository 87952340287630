<template>
  <div>
    <v-form v-model="valid" ref="form" lazy-validation>
      <card-title title="Hipoteka, dugovanje i vrsta tražbine" icon="cash-multiple" />
      <v-card class="pa-4 mb-8 mt-1">
        <v-row>
          <v-col cols="12" md="3">
            <vuetify-money
              v-model="claimData.mortgage"
              class="font-weight-bold"
              label="Hipoteka"
              :options="{ locale: 'us-US', precision: 0, suffix: '€' }" />
          </v-col>
          <v-col cols="12" md="3">
            <vuetify-money
              v-model="claimData.collateral"
              label="Kolateral"
              :options="{ locale: 'us-US', precision: 0, suffix: '€' }" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <vuetify-money
              v-model="claimData.totalDebt"
              label="Ukupno dugovanje"
              :options="{ locale: 'us-US', precision: 0, suffix: '€' }" />
          </v-col>
          <v-col cols="12" md="3">
            <datepicker
              v-model="claimData.totalDebtDate"
              :disabled="!claimData.totalDebt"
              placeholder="Na dan tražbine" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="py-0">
            <v-radio-group v-model="claimData.claimType">
              <template v-slot:label>
                <span class="text-body-1">Vrsta tražbine</span>
              </template>
              <v-radio label="Stečaj" :value="claimType.BANKRUPTCY.value" />
              <v-radio label="Ovrha" :value="claimType.FORECLOSURE.value" />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card>

      <card-title title="Informacije sa suda" icon="gavel" />
      <v-card class="pa-4 mb-8 mt-1">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="claimData.court"
              label="Sud" />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="claimData.judge"
              label="Sudac" />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="claimData.courtItemNumber"
              label="Poslovni broj spisa" />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-if="isBankruptcy"
              v-model="claimData.insolvencyAdministrator"
              label="Stečajni upravitelj" />
            <v-text-field
              v-else
              v-model="claimData.bailiff"
              label="Ovrhovoditelj" />
          </v-col>
        </v-row>
      </v-card>

      <card-title title="Tijek postupka" icon="cog" />
      <v-card class="pa-4 mb-8 mt-1">
        <v-row>
          <v-col>
            <text-editor
              v-model="claimData.situation.HR"
              placeholder="Stanje" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <claim-situation :claimId="claimData.id" :propertyId="propertyId" />
          </v-col>
        </v-row>
      </v-card>

      <v-row>
        <v-col cols="12" md="6">
          <v-btn
            @click="showDeleteDialog = true"
            class="px-10"
            color="red darken-2"
            dark>
            Obriši tražbinu
          </v-btn>
          <delete-modal
            @cancel="showDeleteDialog = false"
            @confirm="remove"
            :show="showDeleteDialog"
            hideDeleteIcon
            subtitle="Jeste li sigurni da želite obrisati unos?"
            title="Obriši unos" />
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <v-btn
            @click="save"
            :disabled="!valid || !hasChanged"
            class="px-10"
            color="success"
            large>
            Spremi promjene
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CardTitle from '../common/CardTitle'
import ClaimSituation from './ClaimSituation'
import claimType from 'common//property/claimType';
import cloneDeep from 'lodash/cloneDeep'
import Datepicker from '../../../common/Datepicker';
import DeleteModal from '../../../common/DeleteModal';
import isEqual from 'lodash/isEqual';
import language from 'common/language';
import TextEditor from '../../../common/TextEditor';

const processTranslations = field => {
  const processedField = {};
  Object.keys(language).forEach(lang => {
    processedField[lang] = field[lang] || '';
  });
  return processedField;
}

const getClaimFields = () => ({
  bailiff: null,
  claimSituations: [],
  claimType: claimType.BANKRUPTCY.value,
  collateral: null,
  court: null,
  courtItemNumber: null,
  insolvencyAdministrator: null,
  judge: null,
  mortgage: null,
  situation: processTranslations({}),
  totalDebt: null,
  totalDebtDate: null,
});

export default {
  name: 'claim-section',
  props: {
    propertyId: { type: Number, required: true }
  },
  data: () => {
    return {
      claimData: { ...getClaimFields() },
      initialClaimData: { ...getClaimFields() },
      showDeleteDialog: false,
      valid: true
    }
  },
  computed: {
    ...mapState('properties', ['property']),
    claimType: () => claimType,
    hasChanged() {
      return !isEqual(this.claimData, this.initialClaimData);
    },
    isBankruptcy() {
      return this.claimData.claimType === claimType.BANKRUPTCY.value;
    }
  },
  methods: {
    ...mapActions('properties', ['removeClaim', 'updateClaim']),
    async remove() {
      const { propertyId } = this;
      await this.removeClaim({ propertyId, claimId: this.claimData.id });
      this.$router.push({ name: 'edit-property', params: { propertyId } });
      this.showDeleteDialog = false;
    },
    async save() {
      return this.updateClaim({ propertyId: this.property.id, ...this.claimData });
    }
  },
  created() {
    this.claimData = cloneDeep(this.property.claim);
    this.initialClaimData = cloneDeep(this.property.claim);
  },
  watch: {
    'property.claim.claimSituations': {
      deep: true,
      handler(val) {
        if (val) {
          this.claimData.claimSituations = this.property.claim.claimSituations;
        }
      }
    },
  },
  components: { CardTitle, ClaimSituation, Datepicker, DeleteModal, TextEditor }
}
</script>

<style scoped lang="scss">
.claim-row {
  background-color: #FFF3E0 !important;
}

::v-deep {
  .ck-content.ck-editor__editable {
    min-height: 250px;
  }
}
</style>
