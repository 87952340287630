module.exports = {
  RESIDENTIAL: {
    key: 'residential',
    value: 'RESIDENTIAL',
    label: { EN: 'Residential', HR: 'Rezidentna' }
  },
  COMMERCIAL: {
    key: 'commercial',
    value: 'COMMERCIAL',
    label: { EN: 'Commercial', HR: 'Komercijalna' }
  }
};
