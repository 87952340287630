var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-header',[_c('h1',[_vm._v("Statistika pregleda nekretnina preko pristupnog linka")])]),_c('v-container',[(_vm.showDialog)?_c('details-dialog',{attrs:{"accessTokenId":_vm.accessTokenId},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}):_vm._e(),_c('div',{staticClass:"access-token-view-wrapper pa-4 mt-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"options":_vm.options,"footer-props":{ itemsPerPageOptions: [5, 10, 20, 50, 100] },"server-items-length":_vm.totalCount,"items":_vm.items,"items-per-page":50,"loading":_vm.isLoading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.property",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'edit-property', params: { propertyId: item.property.id } },"target":"_blank"}},[_vm._v(" "+_vm._s(item.property.title.HR)+" ("+_vm._s(item.property.publicId)+") ")])]}},{key:"item.accessToken.token",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tiny-font"},[_vm._v(_vm._s(item.accessToken.token))])]}},{key:"item.ref",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tiny-font"},[_vm._v(_vm._s(item.ref || '/'))])]}},{key:"item.accessTokenStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tiny-font",class:_vm.getStatusClass(item.accessTokenStatus)},[_vm._v(" "+_vm._s(_vm.getStatusLabel(item.accessTokenStatus))+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tiny-font"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(new Date(item.createdAt)))+" ")])]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.count)+" ")]}},{key:"item.moreDetails",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.openDetails(item.accessTokenId)}}},[_vm._v(" mdi-information-outline ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }