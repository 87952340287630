const ELECTRICITY = {
  key: 'electricity',
  value: 'ELECTRICITY',
  label: { EN: 'Electricity', HR: 'Struja' }
};

const INTERNET_PHONE = {
  key: 'internetPhone',
  value: 'INTERNET_PHONE',
  label: { EN: 'Phone/Internet', HR: 'Telefon/Internet' }
};

const SEWER = {
  key: 'sewer',
  value: 'SEWER',
  label: { EN: 'Sewer', HR: 'Kanalizacija' }
};

const WATER = {
  key: 'water',
  value: 'WATER',
  label: { EN: 'Water', HR: 'Voda' }
};

module.exports = {
  ELECTRICITY,
  INTERNET_PHONE,
  SEWER,
  WATER
};
