<template>
  <draggable v-model="photos" class="row">
    <v-col cols="3" md="2" v-for="(photo, index) in photos" :key="photo.key">
      <v-card class="mt-2 mb-2 item" color="grey lighten-4" flat>
        <delete-modal
          @confirm="remove(index)"
          subtitle="Jeste li sigurni da želite obrisati ovu fotografiju?"
          title="Obriši fotografiju" />
        <a :href="getLink(photo)" target="_blank">
          <v-img :src="photo.sizes.SM.location" aspect-ratio="1" />
        </a>
      </v-card>
    </v-col>
  </draggable>
</template>

<script>
import isEqual from 'lodash/isEqual';
import DeleteModal from '../../common/DeleteModal';
import Draggable from 'vuedraggable';

export default {
  name: 'property-gallery',
  props: {
    value: { type: Array, default: () => [] }
  },
  data() {
    return {
      photos: this.value
    }
  },
  methods: {
    getLink(photo) {
      const availableSize = photo.sizes.XL || photo.sizes.ORIGINAL;
      return availableSize.location;
    },
    remove(index) {
      this.photos.splice(index, 1);
    }
  },
  watch: {
    value() {
      if (!isEqual(this.value, this.photos)) this.photos = this.value;
    },
    photos() {
      this.$emit('input', this.photos);
    }
  },
  components: { DeleteModal, Draggable }
}
</script>

<style lang="scss" scoped>
::v-deep .delete-icon {
  position: absolute !important;
  top: 0.25rem;
  right: 0.25rem;
  color: #B71C1C !important;
  border-radius: 50% !important;
  z-index: 1;
  cursor: pointer;
  background: #fff;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}
</style>
