<template>
  <div class="d-flex flex-row">
    <div class="translations-label">Prijevodi:</div>
    <v-tabs @change="selectTab" right>
      <v-tab
        v-for="lang in languages"
        :key="lang"
        :class="{ 'is-accent': isMissing(lang) }">
        {{ lang }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import language from 'common/language';

export default {
  name: 'language-tabs',
  props: {
    missingTranslations: { type: Array, default: () => [] }
  },
  computed: {
    languages() {
      return Object.keys(language);
    }
  },
  methods: {
    isMissing(lang) {
      return this.missingTranslations.includes(lang)
    },
    selectTab(index) {
      this.$emit('select', this.languages[index]);
    }
  },
  mounted() {
    this.activeTab = 0;
  }
}
</script>

<style scoped lang="scss">
.translations-label {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1rem;
}

.v-tabs {
  width: auto !important;
}

.v-tab {
  padding: 0rem !important;
  min-width: 40px;
}

.v-tab.is-accent {
  &, .v-icon {
    color: #C62828 !important;
  }
}
</style>
