const setLoadingFlag = (state, flag) => (state.isLoading = flag);

const setTag = (state, tag) => {
  const index = state.items.map(item => item.id).indexOf(tag.id);
  if (index !== -1) state.items.splice(index, 1);
  state.items.push(tag);
};

const setTags = (state, tags) => {
  state.items = tags;
};

export {
  setTag, setTags, setLoadingFlag
};
