<template>
  <v-dialog
    v-model="show"
    @click:outside="close"
    @keydown.esc="close"
    max-width="900px"
    scrollable>
    <v-card class="pb-2">
      <v-card-title>
        <span class="headline">Slanje ponude na email</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="isSent" class="mt-4">
        Ponuda je poslana!
      </v-card-text>
      <template v-else>
        <v-card-text>
          <v-row class="pt-4">
            <v-col>Šalje: {{ user.email }}</v-col>
            <v-col class="text-right">
              Prima: <strong>{{ propertyClient.client.email }}</strong>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <text-editor v-model="message" placeholder="Poruka" />
        </v-card-text>
      </template>
      <v-divider></v-divider>
      <v-card-actions class="mt-2 mb-2">
        <v-spacer></v-spacer>
        <v-btn
          @click="$emit('input', false)"
          :color="isSent ? 'success' : ''">
          {{ isSent ? 'Zatvori' : 'Poništi' }}
        </v-btn>
        <v-btn
          v-if="!isSent"
          @click="send"
          :loading="isSending"
          color="success">
          Pošalji ponudu
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TextEditor from '../../../common/TextEditor';

export default {
  name: 'email-offer-dialog',
  props: {
    getTokenLink: { type: Function, required: true },
    propertyClient: { type: Object, required: true },
    property: { type: Object, required: true },
    value: { type: Object, default: null }
  },
  data() {
    return {
      isSending: false,
      isSent: false,
      message: '',
      show: false,
      valid: true
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    accessLink() {
      const tokenLink = this.getTokenLink(this.propertyClient);
      return `${window.location.origin}${tokenLink}?ref=email`;
    }
  },
  methods: {
    ...mapActions('properties', ['sendOffer']),
    close() {
      this.$emit('input', false);
    },
    async send() {
      const sender = this.user.email;
      const params = {
        message: this.message,
        propertyClientId: this.propertyClient.id,
        propertyId: this.property.id,
        propertyTitle: this.property.title.HR,
        publicId: this.property.publicId,
        recipient: this.propertyClient.client.email,
        sender
      };
      this.isSending = true;
      await this.sendOffer(params);
      this.isSent = true;
      this.isSending = false;
    },
  },
  created() {
    this.message = `<p>Poštovani,</p>
    <p> </p>
    <p>Nekretnini <strong><em>${this.property.title.HR}</em></strong> 
    (${this.property.publicId}) možete pristupiti preko linka:</p>
    <p><a href="${this.accessLink}" target="_blank">${this.accessLink}</a></p>
    <p> </p>
    <p>S poštovanjem,<br /><strong>TriKapital Sales Team</strong></p>`;
  },
  watch: {
    value:{
      immediate: true,
      handler() {
        this.show = this.value;
      }
    }
  },
  components: { TextEditor }
}
</script>
