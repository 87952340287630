<template>
  <div>
    <app-header>
      <h1>Stranice</h1>
    </app-header>
    <v-container>
      <div class="page-wrapper pa-4 mt-4">
        <page-dialog v-if="showDialog" v-model="showDialog" />
        <v-btn
          @click="showDialog = true"
          class="mt-3 mb-3"
          color="primary"
          small
          tile>
          <v-icon left>mdi-plus</v-icon>
          Nova stranica
        </v-btn>
        <v-data-table
          :footer-props="{ itemsPerPageOptions: [10, 20, 50, 100, -1] }"
          :headers="headers"
          :items="pages"
          :items-per-page="50"
          :search="search"
          class="elevation-1">
          <template v-slot:top>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pronađi stranicu"
              class="search-field mx-4 pt-6" />
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <div>{{ new Date(item.createdAt) | formatDate }}</div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              @click="navigateTo(item.id)"
              class="action-icon"
              color="blue darken-4"
              small>
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppHeader from '../common/Header';
import contentType from 'common/content/type';
import PageDialog from './PageDialog';

const headers = [
  { text: 'Naslov', sortable: true, value: 'title.HR' },
  { text: 'Dodano', sortable: false, value: 'createdAt' },
  { text: 'Autor', sortable: false, value: 'author.firstName' },
  { text: 'Akcija', sortable: false, value: 'action' },
];

export default {
  name: 'page-list-page',
  props: {
    agentId: { type: Number, default: null }
  },
  data: () => ({
    search: '',
    showDialog: false
  }),
  computed: {
    ...mapGetters('pages', ['pages']),
    headers() {
      return headers;
    }
  },
  methods: {
    ...mapActions('pages', ['fetch']),
    navigateTo(contentId) {
      return this.$router.push({ name: 'edit-page', params: { contentId } });
    }
  },
  created() {
    this.fetch(contentType.PAGE);
  },
  watch: {
    isEditing: {
      immediate: true,
      handler() {
        this.showDialog = this.isEditing;
      }
    }
  },
  components: { AppHeader, PageDialog }

}
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 400px;
}

.page-wrapper {
  background: #fff;
}
</style>
