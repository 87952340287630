<template>
  <ckeditor
    @input="$emit('input', $event)"
    :editor="editor"
    :config="editorConfig"
    :value="value" />
</template>

<script>
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Link from '@ckeditor/ckeditor5-link/src/link';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import DocumentList from '@ckeditor/ckeditor5-list/src/documentlist';
import DocumentListProperties from '@ckeditor/ckeditor5-list/src/documentlistproperties';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TableColumnResize from '@ckeditor/ckeditor5-table/src/tablecolumnresize';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';

const baseToolbar = [
  'bold', 'italic', 'underline',
  '|',
  'bulletedList', 'numberedList', 'outdent', 'indent',
  '|',
  'heading',
  '|',
  'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor',
  '|',
  'removeFormat',
  {
    label: 'Formatting',
    icon: 'text',
    items: [ 'strikethrough', 'subscript', 'superscript', 'code']
  },
  'alignment',
  '-',
  'undo', 'redo',
  '|',
  'findAndReplace',
  '|',
  'horizontalLine',
  '|',
  'sourceEditing'
];

const advancedToolbar = [
  ...baseToolbar,
  'link', 'insertImage', 'insertTable',
  {
    label: 'Insert',
    icon: 'plus',
    items: [ 'highlight', 'blockQuote', 'mediaEmbed', 'codeBlock', 'htmlEmbed' ]
  }
]

export default {
  name: 'text-editor',
  props: {
    entityId: { type: Number, default: null },
    placeholder: { type: String, default: null },
    value: { type: String, default: null },
    hasAdvancedToolbar: { type: Boolean, default: false },
  },
  data: () => ({
    editor: ClassicEditor,
    editorData: ''
  }),
  computed: {
    imageUploadUrl() {
      if (!this.hasAdvancedToolbar) return '';
      if (this.hasAdvancedToolbar && !this.entityId) throw new Error('entityId is missing');
      return `/api/content/${this.entityId}/upload-photo`;
    },
    editorConfig() {
      const config = {
        placeholder: this.placeholder,
        plugins: [
          Alignment,
          Autoformat,
          AutoImage,
          AutoLink,
          BlockQuote,
          Bold,
          Code,
          CodeBlock,
          DocumentList,
          DocumentListProperties,
          Essentials,
          FindAndReplace,
          Font,
          Heading,
          Highlight,
          HorizontalLine,
          HtmlEmbed,
          Image,
          ImageCaption,
          ImageInsert,
          ImageResize,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          MediaEmbed,
          PageBreak,
          Paragraph,
          RemoveFormat,
          SimpleUploadAdapter,
          SourceEditing,
          Strikethrough,
          Subscript,
          Superscript,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TextTransformation,
          Underline
        ],
        toolbar: {
          items: this.hasAdvancedToolbar ? advancedToolbar : baseToolbar,
          shouldNotGroupWhenFull: true
        },
        fontFamily: {
          supportAllValues: true
        },
        fontSize: {
          options: [ 10, 12, 14, 'default', 18, 20, 22 ],
          supportAllValues: true
        },
        htmlEmbed: {
          showPreviews: true
        },
        image: {
          styles: ['alignCenter', 'alignLeft', 'alignRight'],
          resizeOptions: [
            { name: 'resizeImage:original', label: 'Original', value: null },
            { name: 'resizeImage:25', label: '25%', value: '25' },
            { name: 'resizeImage:50', label: '50%', value: '50' },
            { name: 'resizeImage:75', label: '75%', value: '75' }
          ],
          toolbar: [
            'imageTextAlternative', 'toggleImageCaption', '|',
            'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', 'imageStyle:side', '|',
            'resizeImage'
          ],
          insert: {
            integrations: ['insertImageViaUrl']
          }
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true
          }
        },
        link: {
          decorators: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file'
              }
            }
          }
        },
        table: {
          contentToolbar: [
            'tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties', 'toggleTableCaption'
          ]
        }
      };
      if (this.hasAdvancedToolbar) {
        const simpleUpload = {
          // The URL that the images are uploaded to.
          // uploadUrl: `/api/content/${this.entityId}upload-photo`,
          uploadUrl: this.imageUploadUrl,
          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: true,
          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            'X-CSRF-TOKEN': 'CSRF-Token',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        };
        config.simpleUpload = simpleUpload;
      }
      return config;
    }
  },
  components: {
    // eslint-disable-next-line
    ClassicEditor
  }
}
</script>

<style>
/* Import the CKEditor 5 CSS style fix */
@import "../../stylesheets/ckeditor.scss";
</style>
