import category from 'common/property/category';
import condition from 'common/property/condition';
import country from 'common/property/country';
import infrastructureItems from 'common/property/infrastructure';
import map from 'lodash/map';
import orientation from 'common/property/orientation';
import propertyType from 'common/property/type';
import transferRiskLevel from 'common/property/transferRiskLevel';
import sortBy from 'lodash/sortBy';
import surfaceType from 'common/property/surfaceType';
import taxType from 'common/property/taxType';

const getItems = (items, lang) => {
  return map(Object.values(items), ({ id, label, sortValue, value }) => {
    return { id, label: label[lang], sortValue, value };
  })
};

const getSortedItems = (items, lang, key = 'label') => {
  return sortBy(getItems(items, lang), [key]);
}

export const categories = ({ lang }) => {
  return getItems(category, lang);
};

export const conditions = ({ lang }) => {
  return getSortedItems(condition, lang, 'sortValue');
};

export const countries = ({ lang }) => {
  return getItems(country, lang);
};

export const commercial = ({ lang }) => {
  return getSortedItems(propertyType.commercial, lang);
};

export const infrastructure = ({ lang }) => {
  return getSortedItems(infrastructureItems, lang);
};

export const orientations = ({ lang }) => {
  return getSortedItems(orientation, lang);
};

export const residential = ({ lang }) => {
  return getSortedItems(propertyType.residential, lang);
};

export const surfaceTypes = ({ lang }) => {
  return getItems(surfaceType, lang);
};

export const taxTypes = ({ lang }) => {
  return getItems(taxType, lang);
};

export const transferRiskLevels = ({ lang }) => {
  return getItems(transferRiskLevel, lang);
};
