<template>
  <div>
    <app-header>
      <h1>Statistika pregleda nekretnina preko pristupnog linka</h1>
    </app-header>
    <v-container>
      <details-dialog
        v-if="showDialog"
        v-model="showDialog"
        :accessTokenId="accessTokenId" />
      <div class="access-token-view-wrapper pa-4 mt-4">
        <v-data-table
          :headers="headers"
          :options.sync="options"
          :footer-props="{ itemsPerPageOptions: [5, 10, 20, 50, 100] }"
          :server-items-length="totalCount"
          :items="items"
          :items-per-page="50"
          :loading="isLoading"
          class="elevation-1">
          <template v-slot:[`item.property`]="{ item }">
            <router-link
              :to="{ name: 'edit-property', params: { propertyId: item.property.id } }"
              target='_blank'>
              {{ item.property.title.HR }} ({{ item.property.publicId }})
            </router-link>
          </template>
          <template v-slot:[`item.accessToken.token`]="{ item }">
            <span class="tiny-font">{{ item.accessToken.token }}</span>
          </template>
          <template v-slot:[`item.ref`]="{ item }">
            <span class="tiny-font">{{ item.ref || '/' }}</span>
          </template>
          <template v-slot:[`item.accessTokenStatus`]="{ item }">
            <span :class="getStatusClass(item.accessTokenStatus)" class="tiny-font">
              {{ getStatusLabel(item.accessTokenStatus) }}
            </span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span class="tiny-font">
              {{ new Date(item.createdAt) | formatDate }}
            </span>
          </template>
          <template v-slot:[`item.count`]="{ item }">
            {{ item.count }}
          </template>
          <template v-slot:[`item.moreDetails`]="{ item }">
            <v-icon @click="openDetails(item.accessTokenId)">
              mdi-information-outline
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import api from '../../api/accessTokenView';
import AppHeader from '../common/Header';
import debounce from 'lodash/debounce';
import DetailsDialog from './DetailsDialog';

const headers = [
  { text: 'Naziv nekretnine', value: 'property', sortable: false },
  { text: 'Token', value: 'accessToken.token', sortable: false },
  { text: 'Ime klijenta vezanog uz token', value: 'accessToken.propertyClient.client.fullName', sortable: false },
  { text: 'Izvor', value: 'ref', width: 170, sortable: false },
  { text: 'Status', value: 'accessTokenStatus', width: 150, sortable: false },
  { text: 'Datum zadnjeg otvaranja', value: 'createdAt', width: 170, sortable: false },
  { text: 'Ukupno otvaranja', value: 'count', sortable: false },
  { text: '', value: 'moreDetails', sortable: false }
];

export default {
  name: 'access-token-views',
  data: () => ({
    accessTokenId: null,
    isLoading: true,
    items: [],
    options: {},
    search: '',
    showDialog: false,
    totalCount: 0
  }),
  computed: {
    headers() {
      return headers;
    }
  },
  methods: {
    async fetch() {
      this.isLoading = true;
      const { page: currentPage, itemsPerPage } = this.options
      // TODO: Seach is currently unavailable because search is not implemented
      // on backend yet because of complexity reasons
      const { search } = this;
      const { items, count } = await api.fetch({ currentPage, itemsPerPage, search });
      this.items = items;
      this.totalCount = count;
      this.isLoading = false;
    },
    onSearch: debounce(function () {
      return this.fetch();
    }, 700),
    getStatusClass(status) {
      if (!status || status === 'Valid') return '';
      return 'red--text text--darken-2';
    },
    getStatusLabel(status) {
      if (status === 'Expired') {
        return 'Pristupni link istekao, nekretnina nedostupna';
      }
      if (status === 'Invalid') {
        return 'Pristupni link nije pronađen, nekretnina nedostupna';
      }
      return 'Dozvoljen pristup nekretnini';
    },
    openDetails(accessTokenId) {
      this.showDialog = true;
      this.accessTokenId = accessTokenId;
    }
  },
  watch: {
    options: {
      handler () {
        this.fetch()
      },
      deep: true,
    },
    search: 'onSearch'
  },
  components: { AppHeader, DetailsDialog }

}
</script>

<style lang="scss" scoped>
.child-height {
  height: 70px;
}

.search-field {
  max-width: 400px;
}

.client-wrapper {
  background: #fff;
}

.tiny-font {
  font-size: 0.75rem;
}

.properties-count {
  font-weight: bold;
  text-decoration: underline;
  color: #0d47a1;
}

::v-deep {
  .v-data-table th {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    vertical-align: top;
  }

  .children-table.v-data-table {
    td:first-child {
      padding-left: 0 !important;
    }
    td:nth-child(2) {
      padding-left: 10px !important;
    }
    td:nth-child(3) {
      padding-left: 22px !important;
    }

    th {
      height: 1px !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
</style>
