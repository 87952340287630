import * as filters from '../common/filters';
import * as VueGoogleMaps from 'vue2-google-maps'
import App from './App.vue'
import ckeditor from '@ckeditor/ckeditor5-vue2';
import each from 'lodash/each';
import Meta from 'vue-meta'
import router from './router';
import store from './store'
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import VuetifyMoney from "vuetify-money";
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false
Vue.use(ckeditor);
Vue.use(Meta);
Vue.use(VuetifyMoney);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.GOOGLE_API_KEY,
    libraries: 'places,drawing'
  },
})
each(filters, (fn, name) => Vue.filter(name, fn));

new Vue({
  render: h => h(App),
  router,
  store,
  vuetify
}).$mount('#app')
