<template>
  <v-dialog
    @click:outside="close"
    @keydown.esc="close"
    v-model="show"
    max-width="900px"
    scrollable>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-card class="pb-2">
        <div class="d-flex justify-end pr-3 pt-3">
          <v-icon @click="close" class="close-icon" size="30">
            mdi-close-circle-outline
          </v-icon>
        </div>
        <div v-if="isLoading" class="d-flex justify-center my-12">
          <v-progress-circular :width="4" color="primary" indeterminate />
        </div>
        <template v-else>
          <v-card-title>
            <span class="headline">Uredi tag</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-card class="px-4 pb-4 mb-2 mt-4 mx-4">
              <v-row>
                <v-col
                  v-for="code in Object.keys(languages)"
                  :key="code"
                  cols="3" md="6" sm="12">
                  <v-text-field
                    v-model="tagData.name[code]"
                    :label="`Prijevod za jezik: ${languages[code].toLowerCase()}`"
                    :disabled="code === 'HR'" />
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
          <v-divider />
          <v-card-actions class="mt-2 mb-2">
            <v-spacer />
            <v-btn @click="$emit('input', false)">
              Poništi
            </v-btn>
            <v-btn @click="save" :disabled="!valid" color="success">
              Spremi promjene
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import api from '../../../../api/tag';
import cloneDeep from 'lodash/cloneDeep'
import language from 'common/language';
import without from 'lodash/without'

export default {
  name: 'edit-tag-dialog',
  props: {
    tag: { type: Object, default: null },
    tagId: { type: Number, required: true },
    value: { type: Boolean, default: false }
  },
  data() {
    return {
      tagData: { name: {} },
      isLoading: false,
      search: '',
      show: false,
      valid: true
    };
  },
  computed: {
    ...mapState('tags', { allTags: 'items' }),
    tags() {
      return this.allTags.map(({ name }) => name.HR)
    },
    languages: () => language
  },
  methods: {
    ...mapActions('tags', ['fetch', 'update']),
    close() {
      this.$emit('input', false);
    },
    async getTag() {
      this.isLoading = true;
      const tag = await api.get(this.tagId);
      this.tagData = cloneDeep(tag);
      this.isLoading = false;
    },
    async save() {
      this.$refs.form.validate();
      this.$nextTick(async () => {
        if (!this.valid) return;
        await this.update(this.tagData);
        this.$emit('input', false)
      });
    },
    without: without,
  },
  created() {
    this.getTag();
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.show = this.value;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-wrapper  {
  background-color: #ECEFF1;
}
</style>
