<template>
  <v-dialog
    @click:outside="close"
    @keydown.esc="close"
    v-model="show"
    max-width="900px"
    scrollable>
    <v-card class="pb-2">
      <div class="d-flex justify-end pr-3 pt-3">
        <v-icon @click="close" class="close-icon" size="30">
          mdi-close-circle-outline
        </v-icon>
      </div>
      <div v-if="isLoading" class="d-flex justify-center my-12">
        <v-progress-circular :width="4" color="primary" indeterminate />
      </div>
      <template v-else>
        <v-card-title>
          <span class="headline mr-2">Statistika pregleda za nekretninu:</span>
          <router-link
              :to="{ name: 'edit-property', params: { propertyId: property.id } }"
              target='_blank'>
              {{ property.title.HR }} ({{ property.publicId }})
            </router-link>
        </v-card-title>
        <div class="ml-6 mb-4">
          <div>
            Klijent: 
            <span class="font-weight-bold">
              {{ accessToken.propertyClient.client.fullName }}
            </span>
          </div>
          <div>
            Token: 
            <span class="font-weight-bold">
              {{ accessToken.token }}
          </span>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
            :footer-props="{ itemsPerPageOptions: [10, 20, 50, 100, -1] }"
            :headers="headers"
            :items="items"
            :items-per-page="100"
            class="elevation-1"
            must-sort
            sort-by="createdAt"
            sort-desc>
            <template v-slot:[`item.ref`]="{ item }">
              <span class="tiny-font">{{ item.ref || '/' }}</span>
            </template>
            <template v-slot:[`item.accessTokenStatus`]="{ item }">
              <span :class="getStatusClass(item.accessTokenStatus)" class="tiny-font">
                {{ getStatusLabel(item.accessTokenStatus) }}
              </span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              <span class="tiny-font">
                {{ new Date(item.createdAt) | formatDate }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-2 mb-2">
          <v-spacer></v-spacer>
          <v-btn @click="$emit('input', false)">
            Zatvori
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '../../api/accessTokenView';

const headers = [
  { text: 'Izvor', value: 'ref', width: 170 },
  { text: 'Status', value: 'accessTokenStatus', width: 150 },
  { text: 'Datum zadnjeg otvaranja', value: 'createdAt', width: 170 }
];

export default {
  name: 'page-dialog',
  props: {
    accessTokenId: { type: Number, required: true },
    value: { type: Boolean, default: false }
  },
  data() {
    return {
      items: [],
      isLoading: false,
      show: false,
      valid: true
    };
  },
  computed: {
    accessToken() {
      return this.items[0].accessToken;
    },
    headers() {
      return headers;
    },
    property() {
      return this.items[0].property;
    }
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    async getAccessTokenViews() {
      this.isLoading = true;
      const accessTokenViews = await api.getAccessTokenViews(this.accessTokenId);
      this.items = accessTokenViews;
      this.isLoading = false;
    },
    getStatusClass(status) {
      if (!status || status === 'Valid') return '';
      return 'red--text text--darken-2';
    },
    getStatusLabel(status) {
      if (status === 'Expired') {
        return 'Pristupni link istekao, nekretnina nedostupna';
      }
      if (status === 'Invalid') {
        return 'Pristupni link nije pronađen, nekretnina nedostupna';
      }
      return 'Dozvoljen pristup nekretnini';
    },
  },
  created() {
    if (this.accessTokenId) this.getAccessTokenViews();
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.show = this.value;
      }
    }
  }
}
</script>
