<template>
  <div class="label-font">
    <span v-if="index === 0">{{ itemLabel }}</span>
    <span v-if="index === 1" class="grey--text caption ml-1">
      (+{{ totalItems - 1 }})
    </span>
  </div>
</template>

<script>
import truncate from 'truncate';

export default {
  name: 'multiple-selection-slot',
  props: {
    index: { type: Number, required: true },
    label: { type: String, required: true },
    maxLength: { type: Number, default: 25 },
    totalItems: { type: Number, required: true }
  },
  computed: {
    itemLabel() {
      return this.maxLength ? truncate(this.label, this.maxLength) : this.label;
    }
  }
}
</script>
