<template>
  <v-dialog
    v-model="show"
    @click:outside="close"
    @keydown.esc="close"
    max-width="900px"
    scrollable>
    <v-card class="pb-2">
      <v-card-title>
        <span class="headline">
          Pristupni link ({{ propertyClient.client.fullName }})
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div class="mt-6 ml-2">
              <v-btn @click="selectAll" class="mr-4">Odaberi sve</v-btn>
              <v-btn @click="selectDefault" class="mr-4">Odaberi osnovno</v-btn>
              <v-btn @click="deselectAll">Deselektiraj sve</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-treeview
              v-model="selectedFields"
              :items="propertyFields"
              :selection-type="'leaf'"
              selectable
              selected-color="primary"
              return-object
              open-all />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2 mb-2">
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)">
          Poništi
        </v-btn>
        <v-btn @click="saveToken" color="success">
          Spremi promjene
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import get from 'lodash/get';
import { hasExpired } from 'common/util/dates';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

const ADDITIONAL_FIELDS = [
  'property.price', 'property.priceM2', 'property.description', 'property.taxStatus',
  'property.quarter', 'property.address', 'property.coordinates', 'property.mortgage',
  'property.possession', 'property.documents', 'property.externalLinks',
  'claim.court', 'claim.judge', 'claim.situation', 'claim.claimSituation'
];

const DEFAULT_FIELDS = [
  'property.photos', 'property.shortDescription', 'property.islandId',
  'property.regionId', 'property.cityId', 'property.surface', 'property.surfaceType',
  'property.plotSurface', 'property.seaDistance', 'property.beachDistance',
  'property.cityCentreDistance', 'property.seafront', 'property.seaView',
  'property.panoramicView', 'property.floor', 'property.floorsTotal',
  'property.newConstruction', 'property.rooms', 'property.bedrooms',
  'property.bathrooms', 'property.toilets', 'property.constructionYear',
  'property.constructionDeadline', 'property.adaptationYear', 'property.condition',
  'property.energyCertificate', 'property.heating', 'property.cooling',
  'property.parking', 'property.garageParking', 'property.elevator',
  'property.swimmingPool', 'property.gym', 'property.jacuzzi', 'property.sauna',
  'property.orientation', 'property.infrastructure', 'property.buildingPermit',
  'property.locationPermit', 'property.usagePermit',
  'property.derivedConditionSolution', 'property.ownershipCertificate',
  'property.condominium', 'property.condominiumInProcess',
  'property.usagePermitInProcess', 'property.bookOfDepositedContracts',
  'property.minimumTechnicalRequirements', 'property.legalizationInProcess',
  'property.possessionInProcess','property.video', 'property.virtualTour',
  'claim.mortgage', 'claim.collateral', 'claim.totalDebt', 'claim.claimType'
];

const ALL_FIELDS = DEFAULT_FIELDS.concat(ADDITIONAL_FIELDS);

const mapToObject = array => map(array, it => ({ id: it }));

export default {
  name: 'contracts-dialog',
  props: {
    propertyClient: { type: Object, required: true },
    value: { type: Object, default: null }
  },
  data() {
    return {
      selectedFields: [],
      selectedTemplate: 'DEFAULT',
      show: false,
      valid: true
    };
  },
  computed: {
    accessToken() {
      const accessToken = get(this.propertyClient.accessTokens, '[0]');
      if (!accessToken || hasExpired(new Date(accessToken.expiresAt))) return {};
      return accessToken;
    },
    propertyFields() {
      return [
        {
          id: 'property',
          name: 'Informacije o nekretnini',
          children: [
            {
              id: 'basicInfo',
              name: 'Osnovne informacije',
              children: [
                { id: 'property.photos', name: 'Slike' },
                { id: 'property.price', name: 'Prodajna cijena' },
                { id: 'property.priceM2', name: 'Cijena/m2' },
                { id: 'property.taxStatus', name: 'Porezni status' },
                { id: 'property.shortDescription', name: 'Kratki opis' },
                { id: 'property.description', name: 'Detaljan opis' }
              ]
            },
            {
              id: 'property.locationAndSurface',
              name: 'Lokacija i površina',
              children: [
                { id: 'property.islandId', name: 'Otok' },
                { id: 'property.regionId', name: 'Regija' },
                { id: 'property.cityId', name: 'Mjesto' },
                { id: 'property.quarter', name: 'Uža lokacija' },
                { id: 'property.address', name: 'Adresa' },
                { id: 'property.coordinates', name: 'Lokacija na karti' },
                { id: 'property.surface', name: 'Površina objekta' },
                {
                  id: 'property.surfaceType',
                  name: 'Oznaka površine (Brutto/Netto)'
                },
                { id: 'property.plotSurface', name: 'Površina zemljišta' },
                { id: 'property.seaDistance', name: 'Udaljenost od mora' },
                { id: 'property.beachDistance', name: 'Udaljenost od plaže' },
                { id: 'property.cityCentreDistance', name: 'Udaljenost od centra' },
                { id: 'property.seafront', name: 'Prvi red do mora' },
                { id: 'property.seaView', name: 'Pogled na more' },
                { id: 'property.panoramicView', name: 'Panoramski pogled' },

              ]
            },
            {
              id: 'property.buildingInfo',
              name: 'Informacije o objektu',
              children: [
                { id: 'property.floor', name: 'Kat' },
                { id: 'property.floorsTotal', name: 'Ukupno katova' },
                { id: 'property.newConstruction', name: 'Novogradnja' },
                { id: 'property.rooms', name: 'Broj prostorija' },
                { id: 'property.bedrooms', name: 'Broj spavaćih soba' },
                { id: 'property.bathrooms', name: 'Broj kupaona' },
                { id: 'property.toilets', name: 'Broj toaleta' },
                { id: 'property.constructionYear', name: 'Godina izgradnje' },
                { id: 'property.constructionDeadline', name: 'Rok završetka' },
                { id: 'property.adaptationYear', name: 'Godina adaptacije' },
                { id: 'property.condition', name: 'Stanje' },
                { id: 'property.energyCertificate', name: 'Energetska učinkovitost' },
                { id: 'property.heating', name: 'Grijanje' },
                { id: 'property.cooling', name: 'Hlađenje' },
                { id: 'property.parking', name: 'Parking' },
                { id: 'property.garageParking', name: 'Garažno mjesto' },
                { id: 'property.elevator', name: 'Lift' },
                { id: 'property.swimmingPool', name: 'Bazen' },
                { id: 'property.gym', name: 'Teretana' },
                { id: 'property.jacuzzi', name: 'Jacuzzi' },
                { id: 'property.sauna', name: 'Sauna' }
              ]
            },
            {
              id: 'property.otherInfo',
              name: 'Ostale informacije',
              children: [
                { id: 'property.mortgage', name: 'Otkupnina/hipoteka' },
                { id: 'property.possession', name: 'Posjed nekretnine' },
                { id: 'property.orientation', name: 'Orijentacija' },
                { id: 'property.infrastructure', name: 'Osnovna infrastruktura' }
              ]
            },
            {
              id: 'property.permits',
              name: 'Dozvole',
              children: [
                { id: 'property.buildingPermit', name: 'Građevinska dozvola' },
                { id: 'property.locationPermit', name: 'Lokacijska dozvola' },
                { id: 'property.usagePermit', name: 'Uporabna dozvola' },
                {
                  id: 'property.derivedConditionSolution',
                  name: 'Rješenje o izvedenom stanju'
                },
                { id: 'property.ownershipCertificate', name: 'Vlasnički list' },
                { id: 'property.condominium', name: 'Etažirano' },
                {
                  id: 'property.condominiumInProcess',
                  name: 'Etažiranje u postupku'
                },
                {
                  id: 'property.usagePermitInProcess',
                  name: 'Uporabna dozvola u postupku'
                },
                {
                  id: 'property.bookOfDepositedContracts',
                  name: 'Knjiga položenih ugovora'
                },
                {
                  id: 'property.minimumTechnicalRequirements',
                  name: 'Minimalni tehnički uvjeti'
                },
                {
                  id: 'property.legalizationInProcess',
                  name: 'Legalizacija u postupku'
                },
                {
                  id: 'property.possessionInProcess',
                  name: 'Posjed u postupku'
                }
              ]
            },
            { id: 'property.video', name: 'Video' },
            { id: 'property.virtualTour', name: 'Virtualna Šetnja' },
            { id: 'property.documents', name: 'Dokumenti' },
            {
              id: 'property.externalLinks',
              name: 'Vanjski linkovi (katastar/zemljišnik)'
            }
          ]
        },
        {
          id: 'claim',
          name: 'Informacije o tražbini',
          children: [
            {
              id: 'mortgageClaimInfo',
              name: 'Hipoteka, dugovanje i vrsta tražbine',
              children: [
                { id: 'claim.mortgage', name: 'Hipoteka' },
                { id: 'claim.collateral', name: 'Kolateral' },
                { id: 'claim.totalDebt', name: 'Ukupno dugovanje' },
                { id: 'claim.claimType', name: 'Vrsta tražbine' }
              ]
            },
            {
              id: 'claim.courtInfo',
              name: 'Informacije sa suda',
              children: [
                { id: 'claim.court', name: 'Sud' },
                { id: 'claim.judge', name: 'Sudac' }
              ]
            },
            { id: 'claim.situation', name: 'Stanje' },
            { id: 'claim.claimSituation', name: 'Tijek postupka' }
          ]
        }
      ];
    },
    templateItems() {
      return [
        { label: 'Default', value: 'DEFAULT' },
        { label: 'Sve', value: 'ALL' },
        { label: 'Proizvoljno', value: 'CUSTOM' },
      ]
    }
  },
  methods: {
    ...mapActions('properties', ['generateAccessToken', 'updateAccessToken']),
    close() {
      this.$emit('input', false);
    },
    deselectAll() {
      this.selectedFields = [];
    },
    async saveToken() {
      const action = this.accessToken.id
        ? this.updateAccessToken : this.generateAccessToken;
      action({
        id: this.accessToken.id,
        propertyClientId: this.propertyClient.id,
        propertyFields: map(this.selectedFields, 'id')
      });
      this.$emit('input', false)
    },
    selectAll() {
      this.selectedFields = mapToObject(ALL_FIELDS);
    },
    selectDefault() {
      this.selectedFields = mapToObject(DEFAULT_FIELDS);
    },
    setSelectedFields() {
      if (!isEmpty(this.accessToken)) {
        return this.selectedFields = mapToObject(this.accessToken.propertyFields);
      }
      this.selectedFields = mapToObject(DEFAULT_FIELDS);
    }
  },
  created() {
    this.setSelectedFields();
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.show = this.value;
      }
    }
  },
  components: { }
}
</script>
