const APARTMENT = {
  key: 'apartment',
  code: 'AP',
  value: 'APARTMENT',
  label: { EN: 'Apartment/Flat', HR: 'Apartman/Stan' }
};

const CAMP = {
  key: 'camp',
  code: 'CM',
  value: 'CAMP',
  label: { EN: 'Camp', HR: 'Kamp' }
};

const BUSINESS_PREMISES = {
  key: 'businessPremises',
  code: 'BP',
  value: 'BUSINESS_PREMISES',
  label: { EN: 'Business Premises', HR: 'Poslovni prostor' }
};

const COMMERCIAL_BUSINESS_PREMISES = {
  key: 'commercialBusinessPremises',
  code: 'CBP',
  value: 'COMMERCIAL_BUSINESS_PREMISES',
  label: { EN: 'Commercial Business Premises', HR: 'Komercijalni poslovni prostor' }
};

const COMMERCIAL_LAND_PLOT = {
  key: 'commercialLandPlot',
  code: 'CLP',
  value: 'COMMERCIAL_LAND_PLOT',
  label: { EN: 'Commerical Land Plot', HR: 'Komercijalno zemljište' }
};

const COMMERCIAL_RESIDENTIAL_BUILDING = {
  key: 'commercialResidentialBuilding',
  code: 'CRB',
  value: 'COMMERCIAL_RESIDENTIAL_BUILDING',
  label: { EN: 'Commercial Residential Building', HR: 'Poslovno stambena zgrada' }
};

const HOTEL = {
  key: 'hotel',
  code: 'HT',
  value: 'HOTEL',
  label: { EN: 'Hotel', HR: 'Hotel' }
};

const HOUSE = {
  key: 'house',
  code: 'HS',
  value: 'HOUSE',
  label: { EN: 'House', HR: 'Kuća' }
};

const INDUSTRIAL_BUILDING = {
  key: 'industrialBuilding',
  code: 'IB',
  value: 'INDUSTRIAL_BUILDING',
  label: { EN: 'Industrial Building', HR: 'Hala i ekonomsko dvorište' }
};

const LAND_PLOT = {
  key: 'landPlot',
  code: 'LP',
  value: 'LAND_PLOT',
  label: { EN: 'Land Plot', HR: 'Zemljište' }
};

const OTHER = {
  key: 'other',
  code: 'OT',
  value: 'OTHER',
  label: { EN: 'Other', HR: 'Ostalo' }
};

const RESORT = {
  key: 'resort',
  code: 'RS',
  value: 'RESORT',
  label: { EN: 'Resort', HR: 'Odmaralište' }
};

const residential = {
  APARTMENT,
  BUSINESS_PREMISES,
  HOUSE,
  LAND_PLOT
};

const commercial = {
  CAMP,
  COMMERCIAL_BUSINESS_PREMISES,
  COMMERCIAL_LAND_PLOT,
  COMMERCIAL_RESIDENTIAL_BUILDING,
  HOTEL,
  INDUSTRIAL_BUILDING,
  OTHER,
  RESORT
}

const allTypes = {
  ...commercial,
  ...residential
}

module.exports = {
  ...allTypes,
  allTypes, // TODO: Remove
  commercial,
  residential
};
