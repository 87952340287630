import accessTokenViews from './accessTokenViews'
import agents from './agents'
import auth from './auth'
import blog from './blog'
import clients from './clients'
import pages from './pages'
import partners from './partners'
import properties from './properties'
import tags from './tags'
import translations from './translations'

export default {
  accessTokenViews,
  agents,
  blog,
  auth,
  clients,
  pages,
  partners,
  properties,
  tags,
  translations
}
