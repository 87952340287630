<template>
  <div>
    <v-navigation-drawer v-model="drawer" absolute temporary app>
      <vertical-links @logout="signout" :menu-items="menuItems" :user="name" />
    </v-navigation-drawer>
    <v-app-bar app color="primary">
      <template v-if="isMobile">
        <v-app-bar-nav-icon @click.stop="drawer=!drawer" color="white" />
        <logo class="ml-4 mt-2" />
      </template>
      <template v-else>
        <v-container>
          <logo class="mt-1" />
          <v-spacer></v-spacer>
          <horizontal-links @logout="signout" :menu-items="menuItems" :user="name" />
        </v-container>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HorizontalLinks from './HorizontalLinks'
import Logo from './Logo'
import VerticalLinks from './VerticalLinks'

export default {
  data: () => ({
    menuItems: [
      { label: 'Početna', routerName: 'home', isHome: true  },
      {
        label: 'Nekretnine',
        isParent: true,
        children: [
          { label: 'Dodaj novu nekretninu', routerName: 'add-property' },
          { label: 'Statistika pregleda nekretnina', routerName: 'property-view' },
          { label: 'Zahtjevi za pristup (N/A)' }
        ]
      },
      {
        label: 'Entiteti',
        isParent: true,
        children: [
          { label: 'Klijenti', routerName: 'clients' },
          { label: 'Partneri', routerName: 'partners' },
          { label: 'Agenti', routerName: 'agents' },
          { label: 'Tagovi (N/A)' }
        ]
      },
      {
        label: 'Stranice i alati',
        isParent: true,
        children: [
          { label: 'Stranice (N/A)' },
          { label: 'Blog', routerName: 'blog' },
          { label: 'Stranice', routerName: 'pages' },
          { label: 'Konfiguracija (N/A)' }
        ]
      }
    ],
    drawer: false
  }),
  computed: {
    ...mapState('auth', ['user']),
    isMobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    name() {
      return this.user.firstName;
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    signout () {
      this.logout();
      this.$router.push('/login')
    }
  },
  components: { HorizontalLinks, Logo, VerticalLinks }
}
</script>

<style lang="scss" scoped>
.v-app-bar {
  min-height: 64px;
}

a.navigation-link {
  text-decoration: none;
}

a.navigation-link {
  margin-right: 0.5rem;
}

.v-navigation-drawer {
  position: fixed;
  top: 64px !important;
  z-index: 3 !important;
}

.v-toolbar__content {
  height: 100% !important;
}

.container {
  display: flex;
  align-items: center;
}
</style>
