<template>
  <v-dialog
    v-model="show"
    @click:outside="close"
    @keydown.esc="close"
    max-width="900px">
    <v-card class="pb-2">
      <v-card-title>
        <span class="headline">
          Dokumenti i ugovori ({{ propertyClient.client.fullName }})
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-4">
        <v-row>
          <v-col cols="6">
            <contract-upload
              v-model="documents.contactAgreement"
              @delete="setContract('contactAgreement', null)"
              @uploaded="setContract('contactAgreement', $event)"
              :label="'Ugovor o ostvarenom kontaktu'"
              :propertyId="propertyClient.propertyId"
              :subFolder="`client-${propertyClient.clientId}`" />
          </v-col>
          <v-col cols="6">
            <contract-upload
              v-model="documents.NDA"
              @delete="setContract('NDA', null)"
              @uploaded="setContract('NDA', $event)"
              :label="'NDA'"
              :propertyId="propertyClient.propertyId"
              :subFolder="`client-${propertyClient.clientId}`" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <contract-upload
              v-model="documents.mediationAgreement"
              @delete="setContract('mediationAgreement', null)"
              @uploaded="setContract('mediationAgreement', $event)"
              :label="'Ugovor o posredovanju (UOP)'"
              :propertyId="propertyClient.propertyId"
              :subFolder="`client-${propertyClient.clientId}`" />
          </v-col>
          <v-col cols="6">
            <contract-upload
              v-model="documents.proofOfFunds"
              @delete="setContract('proofOfFunds', null)"
              @uploaded="setContract('proofOfFunds', $event)"
              :label="'Proof of funds'"
              :propertyId="propertyClient.propertyId"
              :subFolder="`client-${propertyClient.clientId}`" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2 mb-2">
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)">
          Poništi
        </v-btn>
        <v-btn @click="save" :disabled="!hasChanged" color="success">
          Spremi promjene
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import ContractUpload from '../documents/ContractUpload';
import isEqual from 'lodash/isEqual';
import { mapActions } from 'vuex';

export default {
  name: 'contracts-dialog',
  props: {
    propertyClient: { type: Object, required: true },
    value: { type: Object, default: null }
  },
  data() {
    return {
      documents: null,
      initialDocumentsData: null,
      show: false,
      valid: true
    };
  },
  computed: {
    hasChanged() {
      return !isEqual(this.documents, this.initialDocumentsData);
    },
  },
  methods: {
    ...mapActions('properties', ['updatePropertyClient']),
    close() {
      this.$emit('input', false);
    },
    async save() {
      const propertyClient = { ...this.propertyClient, documents: this.documents };
      await this.updatePropertyClient(propertyClient);
      this.$emit('input', false)
    },
    setContract(contractName, data) {
      this.documents[contractName] = data;
    },
    setDocuments(key) {
      if (this.propertyClient.documents) {
        return this[key] = cloneDeep(this.propertyClient.documents);
      }
      this[key] = {
        contactAgreement: null,
        mediationAgreement: null,
        NDA: null,
        proofOfFunds: null
      };
    }
  },
  created() {
    this.setDocuments('documents');
    this.setDocuments('initialDocumentsData');
  },
  watch: {
    value:{
      immediate: true,
      handler() {
        this.show = this.value;
      }
    }
  },
  components: { ContractUpload }
}
</script>
