import api from '../../../api/content';

const create = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.create(data).then(pageContent => {
    commit('setPageContent', pageContent);
    commit('setLoadingFlag', false);
    return pageContent;
  });
};

const fetch = ({ commit }, type) => {
  commit('setLoadingFlag', true);
  return api.fetch(type).then(pages => {
    commit('setPages', pages);
    commit('setLoadingFlag', false);
    return pages;
  });
};

const get = ({ commit }, id) => {
  return api.get(id).then(pageContent => {
    commit('setPageContent', pageContent);
    return pageContent;
  });
};

const update = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.update(data).then(pageContent => {
    commit('setPageContent', pageContent);
    commit('setLoadingFlag', false);
    return pageContent;
  });
};

export { create, fetch, get, update };
