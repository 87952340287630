<template>
  <v-container class="py-0">
    <v-breadcrumbs :items="items" class="py-2 px-0">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import compact from 'lodash/compact';

const homePage = {
  text: 'Nekretnine',
  disabled: false,
  to: '/',
};
const blog = {
  text: 'Blog',
  disabled: false,
  exact: true,
  to: { name: 'blog' },
};
const pages = {
  text: 'Stranice',
  disabled: false,
  exact: true,
  to: { name: 'pages' },
};
export default {
  computed: {
    ...mapState('properties', ['property']),
    ...mapState('blog', ['blogPost']),
    ...mapState('pages', ['pageContent']),
    isBlog() {
      return this.$route.matched.some(({ name }) => name && name.includes('blog'));
    },
    isPages() {
      const pagesRoutes = ['pages', 'edit-page']
      return this.$route.matched.some(({ name }) => name && pagesRoutes.includes(name));
    },
    items() {
      const currentProperty = this.property ? { text: this.property.title.HR } : null;
      const currentBlogPost = this.blogPost ? { text: this.blogPost.title.HR } : null;
      const currentPage = this.pageContent ? { text: this.pageContent.title.HR } : null;
      if (this.isBlog) return compact([blog, currentBlogPost]);
      if (this.isPages) return compact([pages, currentPage]);
      return compact([homePage, currentProperty]);
    }
  }
}
</script>
