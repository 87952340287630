const EAST = {
  key: 'east',
  value: 'EAST',
  label: { EN: 'East', HR: 'Istok' }
};

const NORTH = {
  key: 'north',
  value: 'NORTH',
  label: { EN: 'North', HR: 'Sjever' }
};

const SOUTH = {
  key: 'south',
  value: 'SOUTH',
  label: { EN: 'South', HR: 'Jug' }
};

const WEST = {
  key: 'west',
  value: 'WEST',
  label: { EN: 'West', HR: 'Zapad' }
};

module.exports = {
  EAST,
  NORTH,
  SOUTH,
  WEST
};
