import api from '../../../api/login'
import apiRequest from '../../../api/request';

const login = async ({ commit }, { email, password }) => {
  const { token, user }  = await api.login({ email, password });
  commit('login', user);
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
  apiRequest.defaults.headers.authorization = `Bearer ${token}`
}

const logout = async ({ commit }) => {
  commit('logout');
  localStorage.clear()
  apiRequest.defaults.headers.authorization = null;
}

export { login, logout };
