import _get from 'lodash/get';
import request from './request';

const BASE_URL = '/content';

const url = {
  content: contentId => `${BASE_URL}/${contentId}`
};

const getData = res => _get(res, 'data', {});

function create(content) {
  return request.post(BASE_URL, content).then(getData);
}

function fetch(type) {
  return request.get(BASE_URL, { params: { type } }).then(getData);
}

function get(id) {
  return request.get(url.content(id)).then(getData);
}

function update(content) {
  return request.patch(`${BASE_URL}/${content.id}`, content).then(getData);
}

function uploadPhoto(data, contentId) {
  return request.post(`${BASE_URL}/${contentId}/upload-photo`, data).then(getData);
}

export default {
  create,
  fetch,
  get,
  update,
  uploadPhoto
};
