<template>
  <div>
    <claim-situation-dialog
      v-if="showDialog"
      v-model="showDialog"
      :claimId="claimId"
      :propertyId="propertyId" />
    <v-btn
      @click="showDialog = true"
      class="mt-3 mb-3"
      color="primary"
      small
      tile>
      <v-icon left>mdi-plus</v-icon>
      Dodaj novi unos
    </v-btn>
    <v-data-table
      :headers="headers"
      :items="claimSituations"
      :items-per-page="-1"
      class="elevation-1"
      hide-default-footer
      sort-by="date"
      sortDesc>
      <template v-slot:top>
        <h3 class="pt-4 ml-4">Tijek postupka</h3>
      </template>
      <template v-slot:[`item.attachments`]="{ item }">
        <document-item
          v-for="(document, index) in item.attachments"
          :key="document.location"
          :allowDelete="false"
          :document="document"
          :index="index" />
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <!-- <v-icon class="action-icon mr-4" color="primary" small>
          mdi-pencil
        </v-icon> -->
        <v-icon
          @click="deletingClaimSituationId = item.id"
          class="action-icon"
          color="red darken-4"
          small>
          mdi-trash-can-outline
        </v-icon>
      </template>
    </v-data-table>
    <delete-modal
      @cancel="deletingClaimSituationId = null"
      @confirm="remove"
      :show="!!deletingClaimSituationId"
      hideDeleteIcon
      subtitle="Jeste li sigurni da želite obrisati unos?"
      title="Obriši unos" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ClaimSituationDialog from './ClaimSituationDialog'
import DeleteModal from '../../../../common/DeleteModal';
import DocumentItem from '../../documents/DocumentItem'
import pick from 'lodash/pick';

const headers = [
  { text: 'Datum promjene', sortable: true, value: 'date' },
  { text: 'Kratak opis sadržaja', value: 'description' },
  { text: 'Prilozi', sortable: false, value: 'attachments' },
  { text: 'Primjedba', value: 'note' },
  { text: 'Akcija', sortable: false, value: 'action' },
];

export default {
  name: 'claim-procedure-progress',
  props: {
    claimId: { type: Number, required: true },
    propertyId: { type: Number, required: true }
  },
  data: () => ({
    deletingClaimSituationId: null,
    showDialog: false
  }),
  computed: {
    ...mapState('properties', ['property']),
    claimSituations() {
      return this.property.claim.claimSituations;
    },
    headers() {
      return headers;
    }
  },
  methods: {
    ...mapActions('properties', ['removeClaimSituation']),
    async remove() {
      const data = pick(this, ['claimId', 'deletingClaimSituationId', 'propertyId'])
      await this.removeClaimSituation(data);
      this.deletingClaimSituationId = null;
    }
  },
  components: { ClaimSituationDialog, DeleteModal, DocumentItem }
}
</script>

<style lang="scss" scoped>
::v-deep .v-item-group.v-tabs-bar {
  background-color: transparent !important;
}

.v-tab.accent-tab {
  &, .v-icon {
    color: #E65100 !important;
  }
}

.action-icon {
  cursor: pointer;
}
</style>
