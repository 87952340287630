import agentApi from '../../../api/agent';
import capitalize from 'lodash/capitalize'
import cloneDeep from 'lodash/cloneDeep'
import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import language from 'common/language';
import locationApi from '../../../api/location';
import partnerApi from '../../../api/partner';
import toLower from 'lodash/toLower'

const processTranslations = field => {
  const processedField = {};
  Object.keys(language).forEach(lang => {
    processedField[lang] = field[lang] || '';
  });
  return processedField;
}

const getPropertyData = () => ({
  active: true,
  actualPrice: null,
  adaptationYear: null,
  address: null,
  agentId: null,
  bathrooms: null,
  beachDistance: null,
  bedrooms: null,
  bookOfDepositedContracts: null,
  buildingPermit: null,
  cadastreMunicipality: null,
  cadastreParcelNumber: null,
  category: null,
  cityId: null,
  cityCentreDistance: null,
  condition: null,
  condominium: null,
  condominiumInProcess: null,
  constructionDeadline: null,
  constructionYear: null,
  contracts: {
    mediationAgreement: null,
    NDA: null
  },
  coordinates: {
    lat: null,
    lng: null
  },
  countryId: 1,
  countyId: null,
  landRegistryExtract: null,
  derivedConditionSolution: null,
  description: processTranslations({}),
  shortDescription: processTranslations({}),
  documents: [],
  energyCertificate: null,
  evaluatedPrice: null,
  externalLinks: {
    katastarHr: null
  },
  featured: false,
  floor: null,
  floorsTotal: null,
  gym: null,
  heating: null,
  cooling: null,
  parking: null,
  garageParking: null,
  elevator: null,
  infrastructure: null,
  islandId: null,
  jacuzzi: null,
  legalizationInProcess: null,
  locationPermit: null,
  minimumTechnicalRequirements: null,
  mortgage: null,
  municipalityId: null,
  newConstruction: null,
  orientation: null,
  ownershipCertificate: null,
  panoramicView: null,
  partnerId: null,
  photos: [],
  plotSurface: null,
  price: null,
  priceM2: null,
  priceOnRequest: false,
  propertyType: null,
  published: false,
  quarter: null,
  regionId: null,
  rooms: null,
  sauna: null,
  seaDistance: null,
  seaView: null,
  seafront: null,
  surface: null,
  surfaceType: null,
  swimmingPool: null,
  taxStatus: 'PPN',
  title: {
    HR: null
  },
  toilets: null,
  transferRiskLevel: null,
  possession: null,
  usagePermit: null,
  usagePermitInProcess: null,
  possessionInProcess: null,
  video: null,
  virtualTour: null
});

const mapOptionItem = ({ id, name }) => {
  return { id, name: capitalize(toLower(name)) };
};

const sortItems = (items, key = 'name') => {
  return items.sort((a, b) => a[key].localeCompare(b[key], 'hr'));
}

export const propertyMixin = {
  data: () => {
    return {
      agents: [],
      cities: [],
      counties: [],
      files: {
        contracts: {
          mediationAgreement: null,
          NDA: null
        },
        documents: []
      },
      initialPropertyData: { ...getPropertyData() },
      islands: [],
      municipalities: [],
      partners: [],
      propertyData: { ...getPropertyData() },
      regions: []
    }
  },
  methods: {
    async fetchAgents() {
      this.agents = [];
      const agents = await agentApi.fetch();
      this.agents = sortItems(agents, 'firstName');
    },
    async fetchCities(municipalityId) {
      this.cities = [];
      if (!municipalityId) return;
      const cities = (await locationApi.getCities(municipalityId));
      this.cities = cities;
      this.cities = sortItems(cities);
    },
    async fetchCounties() {
      this.counties = [];
      const counties = (await locationApi.getCounties(this.propertyData.regionId))
        .map(mapOptionItem);
      this.counties = sortItems(counties);
    },
    async fetchIslands() {
      this.islands = [];
      const islands = await locationApi.getIslands();
      const other = find(islands, ({ name }) => name === 'OSTALO');
      this.islands = sortItems(filter(islands, ({ id }) => id !== other.id))
      this.islands.push(other);
    },
    fetchLocationData() {
      const { countyId, municipalityId } = this.propertyData;
      if (countyId) this.fetchMunicipalities(countyId);
      if (municipalityId) this.fetchCities(municipalityId);
    },
    async fetchMunicipalities(countyId) {
      if (!countyId) return;
      const municipalities = (await locationApi.getMunicipalities(countyId));
      this.municipalities = sortItems(municipalities);
    },
    async fetchPartners() {
      this.partners = [];
      const partners = await partnerApi.fetch();
      this.partners = sortItems(partners);
    },
    async fetchRegions() {
      this.regions = [];
      const regions = (await locationApi.getRegions());
      this.regions = regions;
    },
    async onCountyChange() {
      this.validateNull('countyId');
      await this.fetchMunicipalities(this.propertyData.countyId);
      this.resetLocationFields(['municipalityId', 'cityId']);
    },
    async onMunicipalityChange() {
      this.validateNull('municipalityId');
      await this.fetchCities(this.propertyData.municipalityId);
      this.resetLocationFields(['cityId']);
    },
    async onRegionChange() {
      this.validateNull('regionId');
      await this.fetchCounties();
      this.resetLocationFields(['countyId', 'municipalityId', 'cityId']);
    },
    resetLocationFields(fields) {
      each(fields, key => this.propertyData[key] = null);
    },
    validateNull(key) {
      if (!this.propertyData[key]) this.propertyData[key] = null;
    }
  },
  created() {
    this.fetchCounties();
    this.fetchIslands();
    this.fetchRegions();
    this.fetchPartners();
    this.fetchAgents();
  },
  watch: {
    propertyData: {
      deep: true,
      handler() {
        // this.$emit('input', data);
      }
    },
    property: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.propertyData = cloneDeep(this.property);
          this.initialPropertyData = cloneDeep(this.property);
          this.fetchLocationData();
        }
      }
    },
    $route: {
      immediate: true,
      deep: true,
      handler({ name }) {
        if (name === 'add-property') {
          this.propertyData = getPropertyData();
          this.initialPropertyData = getPropertyData();
        }
      }
    }
  }
};
