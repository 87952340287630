<template>
  <div>
    <app-header>
      <h1>Blog</h1>
    </app-header>
    <v-container>
      <div class="blog-wrapper pa-4 mt-4">
        <blog-post-dialog v-if="showDialog" v-model="showDialog" />
        <v-btn
          @click="showDialog = true"
          class="mt-3 mb-3"
          color="primary"
          small
          tile>
          <v-icon left>mdi-plus</v-icon>
          Novi blog post
        </v-btn>
        <v-data-table
          :footer-props="{ itemsPerPageOptions: [10, 20, 50, 100, -1] }"
          :headers="headers"
          :items="blogPosts"
          :items-per-page="50"
          :search="search"
          class="elevation-1">
          <template v-slot:top>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pronađi blog post"
              class="search-field mx-4 pt-6" />
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <div>{{ new Date(item.createdAt) | formatDate }}</div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              @click="navigateTo(item.id)"
              class="action-icon"
              color="blue darken-4"
              small>
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppHeader from '../common/Header';
import BlogPostDialog from './BlogPostDialog';
import contentType from 'common/content/type';

const headers = [
  { text: 'Naslov', sortable: true, value: 'title.HR' },
  { text: 'Dodano', sortable: false, value: 'createdAt' },
  { text: 'Autor', sortable: false, value: 'author.firstName' },
  { text: 'Akcija', sortable: false, value: 'action' },
];

export default {
  name: 'blog-posts-page',
  props: {
    agentId: { type: Number, default: null }
  },
  data: () => ({
    search: '',
    showDialog: false
  }),
  computed: {
    ...mapGetters('blog', ['blogPosts']),
    headers() {
      return headers;
    }
  },
  methods: {
    ...mapActions('blog', ['fetch']),
    navigateTo(contentId) {
      return this.$router.push({ name: 'edit-blog-post', params: { contentId } });
    }
  },
  created() {
    this.fetch(contentType.BLOG);
  },
  watch: {
    isEditing: {
      immediate: true,
      handler() {
        this.showDialog = this.isEditing;
      }
    }
  },
  components: { AppHeader, BlogPostDialog }

}
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 400px;
}

.blog-wrapper {
  background: #fff;
}
</style>
