'use strict';

const { format, isAfter } = require('date-fns')

const dateFormatLong = 'MMMM DD, YYYY';
const dateFormatShort = 'MM/DD/YYYY';

const formatDate = (date, dateFormat = dateFormatLong) => format(date, dateFormat)
const formatShortDate = date => format(date, dateFormatShort);
const formatTime = date => format(date, 'h:mm A');
const hasExpired = expiresAt => isAfter(Date.now(), expiresAt);

module.exports = {
  dateFormatLong,
  dateFormatShort,
  formatDate,
  formatShortDate,
  formatTime,
  hasExpired
};
