import _get from 'lodash/get';
import request from './request';

const BASE_URL = '/access-token-view';

const url = {
  accessToken: accessTokenId => `${BASE_URL}/${accessTokenId}`
};

const getData = res => _get(res, 'data', {});

function fetch(params) {
  return request.get(BASE_URL, { params }).then(getData);
}

function getAccessTokenViews(accessTokenId) {
  return request.get(url.accessToken(accessTokenId)).then(getData);
}

export default { fetch, getAccessTokenViews };
