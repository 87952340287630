<template>
  <div>
    <app-header>
      <h1>Partneri</h1>
    </app-header>
    <v-container>
      <div class="partner-wrapper pa-4 mt-4">
        <partner-dialog v-if="showDialog" v-model="showDialog" :partnerId="partnerId" />
        <v-btn
          @click="showDialog = true"
          class="mt-3 mb-3"
          color="primary"
          small
          tile>
          <v-icon left>mdi-plus</v-icon>
          Novi partner
        </v-btn>
        <v-data-table
          :footer-props="{ itemsPerPageOptions: [10, 20, 50, 100, -1] }"
          :headers="headers"
          :items="partners"
          :items-per-page="50"
          :search="search"
          class="elevation-1">
          <template v-slot:top>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pronađi partnera"
              class="search-field mx-4 pt-6" />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              @click="goTo(item.id)"
              class="action-icon"
              color="blue darken-4"
              small>
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import AppHeader from '../common/Header';
import PartnerDialog from './Dialog';
import { mapActions, mapGetters } from 'vuex'

const headers = [
  { text: 'Ime', sortable: true, value: 'name' },
  { text: 'Email', value: 'email' },
  { text: 'Mobitel', sortable: false, value: 'phone' },
  { text: 'Vezanih nekretnina', value: 'properties.length' },
  { text: 'Autor', value: 'author.firstName' },
  { text: 'Akcija', sortable: false, value: 'action' },
];

export default {
  name: 'partners-page',
  props: {
    partnerId: { type: Number, default: null }
  },
  data: () => ({
    search: '',
    showDialog: false
  }),
  computed: {
    ...mapGetters('partners', ['partners']),
    headers() {
      return headers;
    },
    isEditing() {
      return !!this.$route.params.partnerId;
    }
  },
  methods: {
    ...mapActions('partners', ['fetch']),
    goTo(partnerId) {
      this.$router.push({ name: 'edit-partner', params: { partnerId } });
    }
  },
  created() {
    this.fetch();
  },
  watch: {
    isEditing: {
      immediate: true,
      handler() {
        this.showDialog = this.isEditing;
      }
    },
    showDialog(val) {
      if (!val && this.$route.name === 'edit-partner') {
        this.$router.push({ name: 'partners' });
      }
    }
  },
  components: { AppHeader, PartnerDialog }

}
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 400px;
}

.partner-wrapper {
  background: #fff;
}
</style>
