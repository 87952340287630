import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const opts = {
  icons: { iconfont: 'mdi' },
  theme: {
    themes: {
      light: {
        primary: '#38526e',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        info: '#005CE8',
        success: '#7D8F00',
        error: '#b71c1c',
      },
    },
  }
}

export default new Vuetify(opts)
