<template>
  <v-dialog v-model="show" persistent max-width="800px" scrollable>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-card class="pb-2">
        <v-card-title>
          <span class="headline">Tijek postupka - novi unos</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <card-title title="Osnovne informacije" icon="cog" />
          <v-card class="px-4 mb-8 mt-4 mx-4">
            <v-row>
              <v-col cols="12" md="6">
                <datepicker
                  v-model="claimSituationData.date"
                  placeholder="Datum promjene"
                  required />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="claimSituationData.description"
                  filled
                  label="Kratak opis sadržaja"
                  rows="3" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="claimSituationData.note"
                  ref="svasta"
                  filled
                  label="Primjedba"
                  rows="3" />
              </v-col>
            </v-row>
          </v-card>
          <card-title title="Prilozi" icon="file-multiple" />
          <v-card class="pa-4 mb-8 mt-1 mx-4">
            <v-row>
              <v-col>
                <file-upload
                  @uploaded="storeAttachment"
                  :claimId="this.claimId"
                  :inputId="'upload-claim-document'"
                  accept="DOCUMENTS"
                  label="Dovucite dokumente ovdje" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <other-documents v-model="claimSituationData.attachments" />
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
        <v-card-actions class="mb-2">
          <v-spacer></v-spacer>
          <v-btn @click="$emit('input', false)">
            Poništi
          </v-btn>
          <v-btn @click="save" color="success">
            Spremi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import CardTitle from '../../common/CardTitle';
import Datepicker from '../../../../common/Datepicker';
import FileUpload from '../../../../common/FileUpload';
import OtherDocuments from '../../documents/OtherDocuments';

export default {
  name: 'claim-situation-dialog',
  props: {
    value: { type: Boolean, default:  false },
    claimId: { type: Number, required: true },
    claimSituation: { type: Object, default: null },
    propertyId: { type: Number, required: true }
  },
  data() {
    return {
      claimSituationData: {
        date: new Date().toISOString().substr(0, 10),
        description: null,
        attachments: [],
        note: null
      },
      show: false,
      valid: true
    };
  },
  methods: {
    ...mapActions('properties', ['createClaimSituation']),
    async save() {
      this.$refs.form.validate();
      this.$nextTick(async () => {
        if (!this.valid) return;
        const { claimId, propertyId } = this;
        const data = {
          ...this.claimSituationData,
          claimId,
          propertyId
        }
        await this.createClaimSituation(data);
        this.$emit('input', false)
      });
    },
    storeAttachment(document) {
      this.claimSituationData.attachments.push(document);
    }
  },
  watch: {
    value:{
      immediate: true,
      handler() {
        this.show = this.value;
      }
    }
  },
  components: { CardTitle, Datepicker, FileUpload, OtherDocuments }
}
</script>
