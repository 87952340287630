
const setLoadingFlag = (state, flag) => (state.isLoading = flag);

const setPageContent = (state, pageContent) => {
  const index = state.items.map(item => item.id).indexOf(pageContent.id);
  if (index !== -1) state.items.splice(index, 1);
  state.items.push(pageContent);
  state.pageContent = pageContent;
};

const setPages = (state, pages) => {
  state.items = pages;
};

const resetPageContent = (state) => {
  state.pageContent = null;
};

export {
  resetPageContent, setPageContent, setPages, setLoadingFlag
};
