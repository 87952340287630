import clientApi from '../../../api/client';
import api from '../../../api/property';

const addClients = ({ commit }, { propertyId, propertyClients}) => {
  return api.addClients(propertyId, propertyClients).then(data => {
    commit('addPropertyClients', data);
  });
};

const create = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.create(data).then(property => {
    commit('setProperty', property);
    commit('setLoadingFlag', false);
    return property;
  });
};

const createClaim = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.createClaim(data.propertyId, data).then(claim => {
    commit('setClaim', claim);
    commit('setLoadingFlag', false);
    return claim;
  });
};

const createClaimSituation = ({ commit }, data) => {
  // commit('setLoadingFlag', true);
  const { propertyId, claimId } = data;
  return api.createClaimSituation(propertyId, claimId, data)
    .then(claimSituation => {
      commit('setClaimSituation', claimSituation);
      // commit('setLoadingFlag', false);
      return claimSituation;
    });
};

const generateAccessToken = ({ commit }, data) => {
  return clientApi.generateAccessToken(data).then(accessToken => {
    commit('setAccessToken', accessToken);
  });
};

const updateAccessToken = ({ commit }, data) => {
  return clientApi.updateAccessToken(data).then(accessToken => {
    commit('setAccessToken', accessToken);
  });
};

const get = ({ commit }, id) => {
  commit('setLoadingFlag', true);
  return api.get(id).then(property => {
    commit('setProperty', property);
    commit('setLoadingFlag', false);
    return property;
  });
};

const removeAccessToken = ({ commit }, data) => {
  return clientApi.removeAccessToken(data).then(() => {
    commit('removeAccessToken', data.propertyClientId);
  });
};

const removeClaim = ({ commit }, data) => {
  const { claimId, propertyId } = data;
  return api.removeClaim(propertyId, claimId)
    .then(() => commit('removeClaim', claimId));
};

const removeClaimSituation = ({ commit }, data) => {
  const { claimId, deletingClaimSituationId: claimSituationId, propertyId } = data;
  return api.removeClaimSituation(propertyId, claimId, claimSituationId)
    .then(() => commit('removeClaimSituation', claimSituationId));
};

const removePropertyClient = ({ commit }, data) => {
  return api.removePropertyClient(data.propertyId, data.propertyClientId)
    .then(() => commit('removePropertyClient', data.propertyClientId));
};

const sendOffer = ({ commit }, data) => {
  return clientApi.sendOffer(data).then(emailMessage => {
    commit('setEmailMessage', emailMessage);
  });
};

const update = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.update(data).then(property => {
    commit('setProperty', property);
    commit('setLoadingFlag', false);
    return property;
  });
};

const updateClaim = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.updateClaim(data.propertyId, data).then(claim => {
    commit('setClaim', claim);
    commit('setLoadingFlag', false);
    return claim;
  });
};

const updateClaimSituation = ({ commit }, data) => {
  const { propertyId, claimId } = data;
  return api.updateClaimSituation(propertyId, claimId, data)
    .then(claimSituation => {
      commit('setClaimSituation', claimSituation);
      return claimSituation;
    });
};

const updatePropertyClient = ({ commit }, data) => {
  return api.updatePropertyClient(data.propertyId, data)
    .then(() => commit('setPropertyClient', data));
};

export {
  addClients,
  create,
  createClaim,
  createClaimSituation,
  generateAccessToken,
  get,
  removeAccessToken,
  removeClaim,
  removeClaimSituation,
  removePropertyClient,
  sendOffer,
  update,
  updateClaim,
  updateClaimSituation,
  updatePropertyClient,
  updateAccessToken
};
