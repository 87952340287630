import api from '../../../api/accessTokenView';

const fetch = ({ commit }) => {
  commit('setLoadingFlag', true);
  return api.fetch().then(accessTokenViews => {
    commit('setAccessTokenViews', accessTokenViews);
    commit('setLoadingFlag', false);
    return accessTokenViews;
  });
};

export { fetch };
