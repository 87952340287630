<template>
  <v-dialog
    @click:outside="cancel"
    @keydown.esc="cancel"
    v-model="dialog"
    width="500">
      <template v-if="!hideDeleteIcon" v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          class="delete-icon"
          :size="iconSize">
          mdi-{{ icon }}
        </v-icon>
      </template>
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ title }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          {{ subtitle }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="cancel" >
            Poništi
          </v-btn>
           <v-btn color="red darken-4" dark @click="confirm" >
            {{ confirmLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  export default {
    props: {
      confirmLabel: { type: String, default: 'Obriši' },
      hideDeleteIcon: { type: Boolean, default: false },
      icon: { type: String, default: 'close-circle-outline' },
      iconSize: { type: Number, default: 22 },
      show: { type: Boolean, default: false },
      subtitle: { type: String, required: true },
      title: { type: String, required: true }
    },
    data () {
      return {
        dialog: false,
      }
    },
    methods: {
      cancel() {
        this.$emit('cancel');
        this.dialog = false;
      },
      confirm() {
        this.$emit('confirm');
        this.dialog = false;
      }
    },
    watch: {
      show() {
        this.dialog = this.show;
      }
    }
  }
</script>
