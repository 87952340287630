<template>
  <div>
    <app-header>
      <h1>Klijenti</h1>
    </app-header>
    <v-container>
      <div class="client-wrapper pa-4 mt-4">
        <client-dialog
          v-if="showClientDialog"
          @saved="onSave"
          v-model="showClientDialog"
          :clientId="clientId" />
        <properties-dialog
          v-if="showPropertiesDialog"
          v-model="showPropertiesDialog"
          :clientId="viewingClientId" />
        <v-btn
          @click="showClientDialog = true"
          class="mt-3 mb-3"
          color="primary"
          small
          tile>
          <v-icon left>mdi-plus</v-icon>
          Novi klijent
        </v-btn>
        <v-data-table
          :footer-props="{ itemsPerPageOptions: [10, 20, 50, 100] }"
          :options.sync="options"
          :headers="headers"
          :items="items"
          :server-items-length="totalCount"
          :loading="isLoading"
          :items-per-page="20"
          :search="search"
          class="elevation-1">
          <template v-slot:top>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pronađi klijenta"
              class="search-field mx-4 pt-6" />
          </template>
          <template v-slot:[`item.fullName`]="{ item }">
            <template>
              {{ item.fullName }}
              <v-tooltip max-width="300" top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="primary" size="16" class="ml-1">
                    mdi-card-account-mail-outline
                  </v-icon>
                </template>
                <div>Email: {{ item.email }}</div>
                <div v-if="item.phone">Mobitel: {{ item.phone }}</div>
              </v-tooltip>
            </template>
          </template>
          <template v-slot:[`item.propertyPreference.budget`]="{ item }">
            <span v-if="item.propertyPreference.budget">
              {{ item.propertyPreference.budget | formatCurrency }}
            </span>
          </template>
          <template v-slot:[`item.properties.length`]="{ item }">
            <a v-if="hasProperties(item.properties)"
              @click="openClientPropertiesDialog(item.id)"
              class="properties-count">
              {{ item.properties.length }}
            </a>
            <span v-else class="red--text text--darken-2">0</span>
          </template>
          <template v-slot:[`item.note`]="{ item }">
            <template v-if="hasLongNote(item.note)">
              {{ item.note.substring(0, 60) }}...
              <v-tooltip max-width="300" top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="primary" size="16">
                    mdi-tooltip-text-outline
                  </v-icon>
                </template>
                <span>{{ item.note }}</span>
              </v-tooltip>
            </template>
            <span v-else>{{ item.note }}</span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <div>{{ new Date(item.createdAt) | formatDate }}</div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              @click="goTo(item.id)"
              class="action-icon"
              color="blue darken-4"
              small>
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import api from '../../api/client';
import AppHeader from '../common/Header';
import ClientDialog from './ClientDialog';
import debounce from 'lodash/debounce';
import PropertiesDialog from './PropertiesDialog';

const headers = [
  { text: 'Ime', sortable: true, value: 'fullName', width: 200 },
  { text: 'Budžet', value: 'propertyPreference.budget', width: 150 },
  { text: 'Vezanih nekretnina', value: 'properties.length', width: 120 },
  { text: 'Potencijalno', value: '', width: 120 },
  { text: 'Napomena', value: 'note' },
  { text: 'Dodao', value: 'author.firstName', width: 90 },
  { text: 'Datum dodavanja', value: 'createdAt', width: 150 },
  { text: 'Akcija', sortable: false, value: 'action' },
];

export default {
  name: 'clients-page',
  props: {
    clientId: { type: Number, default: null }
  },
  data: () => ({
    isLoading: true,
    items: [],
    options: {},
    search: '',
    showClientDialog: false,
    showPropertiesDialog: false,
    totalCount: 0,
    viewingClientId: null
  }),
  computed: {
    ...mapGetters('clients', ['clients']),
    headers() {
      return headers;
    },
    isEditing() {
      return !!this.$route.params.clientId;
    }
  },
  methods: {
    ...mapActions('clients', ['fetch']),
    hasProperties(properties) {
      return properties && properties.length;
    },
    hasLongNote(note) {
      return note && note.length > 70;
    },
    openClientPropertiesDialog(clientId) {
      this.viewingClientId = clientId;
      this.showPropertiesDialog = true;
    },
    goTo(clientId) {
      this.$router.push({ name: 'edit-client', params: { clientId } });
    },
    async fetch() {
      this.isLoading = true;
      const { page: currentPage, itemsPerPage } = this.options
      const { search } = this;
      const { items, count } = await api.fetch({ currentPage, itemsPerPage, search });
      this.items = items;
      this.totalCount = count;
      this.isLoading = false;
    },
    onSave() {
      this.options.page = 1;
      this.fetch();
    },
    onSearch: debounce(function () {
      this.options.page = 1;
      return this.fetch();
    }, 700),
  },
  watch: {
    isEditing: {
      immediate: true,
      handler() {
        this.showClientDialog = this.isEditing;
      }
    },
    showClientDialog(val) {
      if (!val && this.$route.name === 'edit-client') {
        this.$router.push({ name: 'clients' });
      }
    },
    options: {
      handler () {
        this.fetch()
      },
      deep: true,
    },
    search() {
      this.onSearch();
    }
  },
  components: { AppHeader, ClientDialog, PropertiesDialog }

}
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 400px;
}

.client-wrapper {
  background: #fff;
}

.properties-count {
  font-weight: bold;
  text-decoration: underline;
  color: #0d47a1;
}

::v-deep {
  .v-data-table tbody tr:nth-of-type(even) {
      // background-color: #E3F2FD;
  }
  .v-data-table th {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    vertical-align: top;
    // background: #FFEBEE !important;
  }
}
</style>
