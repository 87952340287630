<template>
  <v-dialog
    @click:outside="close"
    @keydown.esc="close"
    v-model="show"
    max-width="900px"
    scrollable>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-card class="pb-2">
        <div class="d-flex justify-end pr-3 pt-3">
          <v-icon @click="close" class="close-icon" size="30">
            mdi-close-circle-outline
          </v-icon>
        </div>
        <div v-if="isLoading" class="d-flex justify-center my-12">
          <v-progress-circular :width="4" color="primary" indeterminate />
        </div>
        <template v-else>
          <v-card-title>
            <span class="headline">
              {{ clientId ? 'Uredi podatke o klijentu' : 'Novi klijent' }}
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-card class="px-4 pb-4 mb-2 mt-4 mx-4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="clientData.firstName"
                    label="Ime" />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="clientData.lastName"
                    label="Prezime" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="clientData.email"
                    :rules="[rules.required, rules.email]"
                    label="Email" />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="clientData.phone"
                    label="Telefon" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="clientData.address"
                    label="Adresa" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="clientData.note"
                    outlined
                    label="Napomena"
                    rows="3" />
                </v-col>
              </v-row>
              <div class="filters-wrapper px-4 py-2">
                <h4 class="mt-2">Filteri</h4>
                <span class="text-caption font-weight-thin">
                  Aplikacija će na osnovu zadanog filtera za odabranog korisnika
                  preporučiti nekretnine koje zadovoljavaju definirane kriterije.
                </span>
                <v-divider class="mt-2" />
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="clientData.propertyPreference.budget"
                      label="Budžet"
                      suffix="€"
                      type="number" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="clientData.propertyPreference.category"
                      @input="validateNull('propertyPreference.category')"
                      :items="categories"
                      clearable
                      item-text="label"
                      label="Kategorija" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="clientData.propertyPreference.propertyType"
                      @input="validateNull('propertyPreference.propertyType')"
                      :items="propertyTypes"
                      :disabled="!clientData.propertyPreference.category"
                      clearable
                      item-text="label"
                      label="Tip nekretnine" />
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="12" md="5">
                    <v-select
                      v-model="clientData.propertyPreference.regionId"
                      @input="onRegionChange"
                      :items="regions"
                      clearable
                      item-text="name"
                      item-value="id"
                      label="Regija" />
                  </v-col>
                  <v-col cols="12" md="2" class="d-flex justify-end">
                    <v-checkbox
                      v-model="clientData.propertyPreference.isIsland"
                      label="Otok" />
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-select
                      v-model="clientData.propertyPreference.islandId"
                      @input="validateNull('propertyPreference.islandId')"
                      :disabled="!clientData.propertyPreference.isIsland"
                      :items="islands"
                      clearable
                      item-text="name"
                      item-value="id"
                      label="Odaberi otoke"
                      multiple>
                      <template v-slot:selection="{ item, index }">
                        <selection-slot
                          :index="index"
                          :label="item.name"
                          :totalItems="clientData.propertyPreference.islandId.length" />
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="clientData.propertyPreference.countyId"
                      @input="onCountyChange"
                      :items="counties"
                      clearable
                      item-text="name"
                      item-value="id"
                      label="Županija" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="clientData.propertyPreference.municipalityId"
                      @input="onMunicipalityChange"
                      :disabled="!clientData.propertyPreference.countyId"
                      :items="municipalities"
                      clearable
                      item-text="name"
                      item-value="id"
                      label="Općina" />
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mt-2 mb-2">
            <v-spacer></v-spacer>
            <v-btn @click="$emit('input', false)">
              Poništi
            </v-btn>
            <v-btn @click="save" :disabled="!valid" color="success">
              {{ clientId ? 'Spremi promjene' : 'Dodaj novog klijenta' }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import api from '../../api/client';
import capitalize from 'lodash/capitalize'
import category from 'common/property/category';
import cloneDeep from 'lodash/cloneDeep'
import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import locationApi from '../../api/location';
import orderBy from 'lodash/orderBy'
import SelectionSlot from '../common/MultipleSelectionSlot.vue';
import toLower from 'lodash/toLower'

const mapOptionItem = ({ id, name }) => {
  return { id, name: capitalize(toLower(name)) };
};

const sortItems = (items, key = 'name') => {
  return items.sort((a, b) => a[key].localeCompare(b[key]));
}

export default {
  name: 'client-dialog',
  props: {
    client: { type: Object, default: null },
    clientId: { type: Number, default: null },
    value: { type: Boolean, default: false }
  },
  data() {
    return {
      cities: [],
      clientData: {
        address: null,
        email: null,
        firstName: null,
        lastName: null,
        note: null,
        phone: null,
        propertyPreference: {
          budget: null,
          category: null,
          countyId: null,
          municipalityId: null,
          cityId: null,
          isIsland: false,
          islandId: [],
          propertyType: null
        }
      },
      counties: [],
      islands: [],
      isLoading: false,
      municipalities: [],
      regions: [],
      show: false,
      valid: true
    };
  },
  computed: {
    ...mapGetters('translations', ['categories','commercial','residential']),
    propertyTypes() {
      const { category: selectedCategory } = this.clientData.propertyPreference;
      return selectedCategory === category.COMMERCIAL.value
        ? this.commercial
        : this.residential
    },
    requiredMessage() {
      return 'Ovo polje je obavezno';
    },
    rules() {
      return {
        required: value => !!value || this.requiredMessage,
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Neispravna e-mail adresa'
        },
      };
    }
  },
  methods: {
    ...mapActions('clients', ['create', 'update']),
    close() {
      this.$emit('input', false);
    },
    async fetchCities(municipalityId) {
      this.cities = [];
      if (!municipalityId) return;
      const cities = (await locationApi.getCities(municipalityId)).map(mapOptionItem);
      this.cities = orderBy(cities, ['name']);
    },
    async fetchCounties() {
      this.counties = [];
      const { regionId } = this.clientData.propertyPreference;
      const counties = (await locationApi.getCounties(regionId))
        .map(mapOptionItem);
      this.counties = orderBy(counties, ['name']);
    },
    async fetchMunicipalities(countyId) {
      if (!countyId) return;
      const municipalities = (await locationApi.getMunicipalities(countyId))
        .map(mapOptionItem);
      this.municipalities = orderBy(municipalities, ['name']);
    },
    async fetchIslands() {
      this.islands = [];
      const islands = (await locationApi.getIslands()).map(mapOptionItem);
      const other = find(islands, ({ name }) => name === 'Ostalo');
      this.islands = sortItems(filter(islands, ({ id}) => id !== other.id))
      this.islands.push(other);
    },
    async fetchRegions() {
      this.regions = [];
      const regions = (await locationApi.getRegions()).map(mapOptionItem);
      this.regions = regions;
    },
    async getClient() {
      this.isLoading = true;
      const client = await api.get(this.clientId);
      this.clientData = cloneDeep(client);
      this.initialClientData = cloneDeep(client);
      this.isLoading = false;
    },
    async onCountyChange() {
      this.validateNull('countyId');
      await this.fetchMunicipalities(this.clientData.propertyPreference.countyId);
      this.resetLocationFields(['municipalityId', 'cityId']);
    },
    async onMunicipalityChange() {
      this.validateNull('municipalityId');
      await this.fetchCities(this.clientData.propertyPreference.municipalityId);
      this.resetLocationFields(['cityId']);
    },
    async onRegionChange() {
      this.validateNull('regionId');
      await this.fetchCounties();
      this.resetLocationFields(['countyId', 'municipalityId', 'cityId']);
    },
    resetLocationFields(fields) {
      each(fields, key => this.clientData.propertyPreference[key] = null);
    },
    async save() {
      this.$refs.form.validate();
      this.$nextTick(async () => {
        if (!this.valid) return;
        const save = this.clientId ? this.update : this.create;
        await save(this.clientData);
        this.$emit('saved');
        this.$emit('input', false);
      });
    },
    validateNull(key) {
      if (!this.clientData.propertyPreference[key]) {
        this.clientData.propertyPreference[key] = null;
      }
    }
  },
  created() {
    if (this.clientId) this.getClient();
    this.fetchCounties();
    this.fetchIslands();
    this.fetchRegions();
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.show = this.value;
      }
    }
  },
  components: { SelectionSlot }
}
</script>

<style lang="scss" scoped>
.filters-wrapper  {
  background-color: #ECEFF1;
}
</style>
