<template>
  <v-dialog
    v-model="show"
    @click:outside="close"
    @keydown.esc="close"
    max-width="900px"
    scrollable>
    <v-card class="pb-2">
      <v-card-title>
        <span class="headline">Poslane ponude</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <div v-if="!emailMessages.length" class="d-flex justify-center my-12">
          <v-progress-circular :width="4" color="primary" indeterminate />
        </div>
        <template v-else>
          <div v-for="message in emailMessages" :key="message.id" class="my-12">
            <v-row dense>
              <v-col>
                <strong>Poslao: {{ message.author.firstName }}</strong>
                ({{ new Date(message.createdAt) | formatDate }})
              </v-col>
              <v-col class="text-right">
                <strong>Prima: {{ message.recipients.ToAddresses[0] }}</strong>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col><div v-html="message.textMessage"></div></v-col>
            </v-row>
            <v-divider></v-divider>
          </div>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2 mb-2">
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)">
          Zatvori
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '../../../../api/client';
import pick from 'lodash/pick';

export default {
  name: 'sent-email-offers-dialog',
  props: {
    propertyClientId: { type: Number, required: true },
    propertyId: { type: Number, required: true },
    value: { type: Number, default: null }
  },
  data() {
    return {
      emailMessages: [],
      show: false
    };
  },
  methods: {
    close() {
      this.$emit('input', false);
    }
  },
  async created() {
    const params = pick(this, ['propertyClientId', 'propertyId']);
    const messages = await api.fetchSentOffers(params);
    this.emailMessages = messages;
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.show = this.value;
      }
    }
  }
}
</script>
