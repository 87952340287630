<template>
  <div>
    <v-icon class="drag-icon">mdi-drag</v-icon>
    <v-chip
      @click:close="$emit('deletePropertyTag', tag.id, index)"
      color="primary"
      close>
      {{ tag.name.HR }}
    </v-chip>
    <v-btn
      @click="$emit('editTag', tag.id)"
      class="ml-3"
      x-small>
      <v-icon left>mdi-pencil</v-icon>
      Uredi prijevode
    </v-btn>
    <span v-if="missingTranslations" class="missing-translations ml-2">
      (Nema prijevoda za: {{ missingTranslationsLabel }})
    </span>
  </div>
</template>

<script>
import difference from 'lodash/difference';
import language from 'common/language';

export default {
  name: 'tag-item',
  props: {
    index: { type: Number, required: true },
    tag: { type: Object, required: true }
  },
  computed: {
    missingTranslations() {
      const tagTranslatedLangs = Object.keys(this.tag.name);
      const availableLangs = Object.keys(language);
      return difference(availableLangs, tagTranslatedLangs);
    },
    missingTranslationsLabel() {
      return this.missingTranslations.map(code => language[code]).join(', ');
    },
  }
}
</script>

<style scoped lang="scss">
.missing-translations {
  font-size: 0.75rem;
  color: #B71C1C;
}
.drag-icon {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}
</style>
