<template>
  <div>
    <template v-for="item in menuItems">
      <v-menu v-if="item.children" :key="item.label" open-on-hover offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :to="getRoute(item)" color="white" text exact>
            {{ item.label }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="child in item.children"
              :key="child.label"
              :to="getRoute(child)">
              <v-list-item-title>{{ child.label }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <template v-else>
        <v-btn
          :key="item.label"
          :to="{ name: item.routerName }"
          class="navigation-link"
          color="white"
          text
          exact
          exact-active-class="link-active">
          {{ item.label }}
        </v-btn>
      </template>
    </template>
    <v-menu open-on-hover offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="white" text exact>
          <v-icon class="mr-2">mdi-account-outline</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group color="primary">
          <v-list-item>
            <v-list-item-title>Postavke</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$emit('logout')">
              Odjava ({{ user }})
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'navigation-links',
  props: {
    menuItems: { type: Array, required: true },
    user: { type: String, default: null }
  },
  methods: {
    getRoute(menuItem) {
      return menuItem.routerName && { name: menuItem.routerName };
    }
  }
}
</script>
