
const setLoadingFlag = (state, flag) => (state.isLoading = flag);

const setBlogPost = (state, blogPost) => {
  const index = state.items.map(item => item.id).indexOf(blogPost.id);
  if (index !== -1) state.items.splice(index, 1);
  state.items.push(blogPost);
  state.blogPost = blogPost;
};

const setBlogPosts = (state, blogPosts) => {
  state.items = blogPosts;
};

const resetBlogPost = (state) => {
  state.blogPost = null;
};

export {
  resetBlogPost, setBlogPost, setBlogPosts, setLoadingFlag
};
