import * as getters from './getters';

const state = {
  lang: 'HR'
};

export default {
  namespaced: true,
  getters,
  state
};
