<template>
  <v-app id="app" class="d-flex">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style lang="scss">
@import "./stylesheets/main";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 4rem;
  background-color: #f7f7f7;

  .v-application--wrap {
    min-height: calc(100vh - 4rem);
  }
}
</style>
