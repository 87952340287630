import api from '../../../api/tag';

const create = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.create(data).then(tag => {
    commit('setTag', tag);
    commit('setLoadingFlag', false);
    return tag;
  });
};

const fetch = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.fetch(data).then(tag => {
    commit('setTags', tag);
    commit('setLoadingFlag', false);
    return tag;
  });
};

const get = ({ commit }, id) => {
  return api.get(id).then(tag => {
    commit('setTag', tag);
    return tag;
  });
};

const update = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.update(data).then(tag => {
    commit('setTag', tag);
    commit('setLoadingFlag', false);
    return tag;
  });
};

export { create, fetch, get, update };
