<template>
  <div class="admin d-flex flex-column pt-6">
    <navigation />
    <breadcrumbs />
    <router-view />
    <app-footer />
  </div>
</template>

<script>
import AppFooter from './common/Footer'
import Breadcrumbs from './common/Breadcrumbs.vue'
import Navigation from './common/Navigation'

export default {
  name: 'admin',
  metaInfo() {
    return {
      title: 'TriKapital MLS',
      titleTemplate: 'TriKapital MLS | %s'
    }
  },
  components: { AppFooter, Breadcrumbs, Navigation }
}
</script>

<style>
.admin {
  flex: auto;
}
</style>
