'use strict'

import Vue from 'vue';

const addPropertyClients = (state, propertyClients) => {
  state.property.clients.push(...propertyClients);
};

const removeAccessToken = (state, propertyClientId) => {
  const propertyClientIndex = state.property.clients
    .map(item => item.id)
    .indexOf(propertyClientId);
    Vue.set(state.property.clients[propertyClientIndex], 'accessTokens', []);
};

const removeClaim = (state) => {
  state.property.claim = {};
};

const removeClaimSituation = (state, claimSituationId) => {
  const index = state.property.claim.claimSituations
    .map(item => item.id)
    .indexOf(claimSituationId);
  state.property.claim.claimSituations.splice(index, 1);
};

const removePropertyClient = (state, propertyClientId) => {
  const index = state.property.clients
    .map(item => item.id)
    .indexOf(propertyClientId);
  state.property.clients.splice(index, 1);
};

const setAccessToken = (state, accessToken) => {
  const propertyClientIndex = state.property.clients
    .map(item => item.id)
    .indexOf(accessToken.propertyClientId);
  Vue.set(state.property.clients[propertyClientIndex], 'accessTokens', [accessToken]);
};

const setLoadingFlag = (state, flag) => (state.isLoading = flag);

const setProperty = (state, property) => {
  state.property = property;
};

const setClaim = (state, claim) => {
  state.property.claim = claim;
};

// TODO: Find specific CS or push if doesnt exist
// TODO: Extract claimSituations to state.claimSituations
//  in order to avoid bug when new situation is added and claim is updated
//  afterwards
const setClaimSituation = (state, claimSituation) => {
  state.property.claim.claimSituations.push(claimSituation);
};

const setEmailMessage = (state, emailMessage) => {
  const propertyClientIndex = state.property.clients
    .map(item => item.id)
    .indexOf(emailMessage.propertyClientId);
  const propertyClient = state.property.clients[propertyClientIndex];
  Vue.set(propertyClient, 'emailMessages', [emailMessage.id]);
};

// TODO: Find specific PropertyClient and update or push if doesnt exist
const setPropertyClient = (state, propertyClient) => {
  const index = state.property.clients
    .map(item => item.id)
    .indexOf(propertyClient.id);
  if (index !== -1) state.property.clients.splice(index, 1);
  state.property.clients.push(propertyClient);
};

export {
  addPropertyClients,
  removeAccessToken,
  removeClaim,
  removeClaimSituation,
  removePropertyClient,
  setAccessToken,
  setClaim,
  setClaimSituation,
  setEmailMessage,
  setLoadingFlag,
  setProperty,
  setPropertyClient
};
