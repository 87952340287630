import api from '../../../api/partner';

const create = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.create(data).then(partner => {
    commit('setPartner', partner);
    commit('setLoadingFlag', false);
    return partner;
  });
};

const fetch = ({ commit }, excludedIds) => {
  commit('setLoadingFlag', true);
  return api.fetch(excludedIds).then(partner => {
    commit('setPartners', partner);
    commit('setLoadingFlag', false);
    return partner;
  });
};

const get = ({ commit }, id) => {
  return api.get(id).then(partner => {
    commit('setPartner', partner);
    return partner;
  });
};

const update = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.update(data).then(partner => {
    commit('setPartner', partner);
    commit('setLoadingFlag', false);
    return partner;
  });
};

export { create, fetch, get, update };
