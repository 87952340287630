<template>
  <div>
    <div class="d-flex pb-2">
      <v-row dense>
        <v-col dense cols="12" lg="3" md="6">
          <gmap-autocomplete
            @place_changed="setPlace"
            :options="{ componentRestrictions: { country: ['hr'] } }"
            class="place-input"
            placeholder="Pronađi lokaciju" />  
        </v-col>
        <v-col dense cols="12" lg="3" md="6">
          <v-btn @click="setMarker" class="mb-2" color="primary" width="100%">
            Postavi marker na centar mape
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <gmap-map
      :center="mapPosition"
      :options="{ rotateControl: false, scrollwheel: true }"
      :tilt="0"
      :zoom="hasMarker() ? 16 : 12"
      ref="gmap"
      map-type-id="hybrid"
      style="width: 100%; height: 500px">
      <gmap-marker
        v-if="markerPosition"
        @dragend="updateCoordinates"
        :draggable="true"
        :icon="{ url: require('../../../../assets/map-marker.png') }"
        :position="markerPosition" />
    </gmap-map>
  </div>
</template>

<script>
const getDefaultCoordinates = () => ({ lat: 43.5081, lng: 16.4402 });

export default {
  name: 'google-map',
  props: {
    initialMarkerPosition: { type: Object, default: null }
  },
  data() {
    return {
      markerPosition: this.getMarkerPosition(),
      mapPosition: this.getMapPosition()
    }
  },
  methods: {
    emitMarkerCoordinates(coordinates) {
      this.$emit('updateCoordinates', coordinates);
    },
    getCurrentMapCenter() {
      return this.$refs.gmap.$mapPromise.then((map) => ({
        lat: map.center.lat(),
        lng: map.center.lng()
      }));
    },
    getMapPosition() {
      return this.hasMarker() ? this.initialMarkerPosition : getDefaultCoordinates();
    },
    getMarkerPosition() {
      return this.hasMarker() ? this.initialMarkerPosition : null;
    },
    hasMarker() {
      return !!this.initialMarkerPosition &&
        !!this.initialMarkerPosition.lat &&
        !!this.initialMarkerPosition.lng;
    },
    async setMarker() {
      this.markerPosition = await this.getCurrentMapCenter();
      this.emitMarkerCoordinates(this.markerPosition);
    },
    setPlace(place) {
      if (!place.geometry) return;
      const coordinates = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      this.mapPosition = coordinates;
      this.$refs.gmap.$mapPromise.then((map) => map.setZoom(17));
    },
    updateCoordinates(location) {
      const coordinates = {
        lat: location.latLng.lat(), lng: location.latLng.lng()
      };
      this.$emit('updateCoordinates', coordinates);
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .place-input {
    // min-width: 300px;
    width: 100%;
    border: 1px solid #38526e;
    border-radius: 6px;
    padding: 0.35rem 1rem;
    margin-bottom: 0.5rem !important;
    display: inline-flex;
    align-items: center;
  }
}
</style>
