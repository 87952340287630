<template>
  <div>
    <app-header>
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <h1>Lista nekretnina i tražbina</h1>
          <span class="grey--text text--darken-1 font-size-1">
            Ukupno rezultata: {{ totalCount }}
          </span>
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <create-button />
        </v-col>
      </v-row>
    </app-header>
    <v-container>
      <v-row no-gutters>
        <v-col>
          <search @input="search" v-model="searchText" label="Pronađi nekretninu" />
          </v-col>
        <v-col class="text-right">
          <v-icon class="mr-2" color="grey" size="39">
            mdi-format-list-bulleted
          </v-icon>
          <v-icon class="mr-4" color="primary" size="40">mdi-table</v-icon>
        </v-col>
      </v-row>
      <filter-bar @input="filters = $event" />
      <div v-if="isLoading" class="d-flex justify-center mt-12">
        <v-progress-circular :width="4" color="primary" indeterminate />
      </div>
      <div v-else class="mb-4">
        <div v-if="!groupedProperties.length" class="text-center py-10 my-10">
          Nije pronađena nijedna nekretnina za odabrane kriterije.
        </div>
        <template v-else>
          <div class="d-flex justify-end mb-4">
            <v-pagination
              v-model="currentPage"
              @input="fetch"
              :length="totalPages"
              :total-visible="11" />
          </div>
          <v-row v-for="(row, index) in groupedProperties" :key="index">
            <v-col v-for="property in row" :key="property.id" cols="12" md="6">
              <property :property="property" />
            </v-col>
          </v-row>
        </template>
        <div class="d-flex justify-end mt-6">
          <v-pagination
            v-model="currentPage"
            @input="fetch"
            :length="totalPages"
            :total-visible="11" />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import api from '../../api/property';
import AppHeader from '../common/Header';
import chunk from 'lodash/chunk'
import CreateButton from './CreateButton';
import debounce from 'lodash/debounce';
import FilterBar from './Filter';
import Property from './Property';
import Search from '../common/form/Search';

const ITEMS_PER_PAGE = 20;

export default {
  name: 'properties',
  data: () => ({
    filters: {},
    isLoading: false,
    properties: [],
    searchText: '',
    totalCount: 0,
    currentPage: 1
  }),
  computed: {
    groupedProperties() {
      return chunk(this.properties, 2);
    },
    totalPages() {
      return Math.ceil(this.totalCount / ITEMS_PER_PAGE);
    }
  },
  methods: {
    search: debounce(function () {
      return this.fetch();
    }, 700),
    async fetch() {
      const options = { itemsPerPage: ITEMS_PER_PAGE, currentPage: this.currentPage };
      const params = { ...options, ...this.filters, searchText: this.searchText };
      this.isLoading = true;
      const { properties, count } = await api.fetch(params);
      this.properties = properties;
      this.totalCount = count;
      this.isLoading = false;
    }
  },
  created() {
    this.fetch();
  },
  watch: {
    filters: {
      deep: true,
      handler: 'fetch'
    }
  },
  components: { AppHeader, CreateButton, FilterBar, Property, Search }
}
</script>
