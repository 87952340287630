module.exports = {
  BANKRUPTCY: {
    key: 'bankruptcy',
    value: 'BANKRUPTCY',
    label: { HR: 'Stečaj' }
  },
  FORECLOSURE: {
    key: 'foreclosure',
    value: 'FORECLOSURE',
    label: { HR: 'Ovrha' }
  }
};
