import _get from 'lodash/get';
import request from './request';

const BASE_URL = '/location';
const getData = res => _get(res, 'data', {});
const url = {
  cities: municipalityId => `${BASE_URL}/cities?municipalityId=${municipalityId}`,
  counties: `${BASE_URL}/counties`,
  countries: `${BASE_URL}/countries`,
  islands: `${BASE_URL}/islands`,
  municipalities: countyId => `${BASE_URL}/municipalities?countyId=${countyId}`,
  regions: `${BASE_URL}/regions`
};

function getCities(municipalityId) {
  return request.get(url.cities(municipalityId)).then(getData);
}

function getCounties(regionId) {
  const params = { regionId };
  return request.get(url.counties, { params }).then(getData);
}

function getCountries() {
  return request.get(url.countries).then(getData);
}

function getIslands() {
  return request.get(url.islands).then(getData);
}

function getMunicipalities(countyId) {
  return request.get(url.municipalities(countyId)).then(getData);
}

function getRegions() {
  return request.get(url.regions).then(getData);
}

export default {
  getCities, getCounties, getCountries, getIslands, getMunicipalities, getRegions
};
