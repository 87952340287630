<template>
  <div>
    <app-header>
      <h1>Uredi stranicu</h1>
    </app-header>
    <v-container v-if="contentData">
      <v-form v-model="valid" ref="form" class="page-wrapper pa-4 mt-4" lazy-validation>
        <translation-tabs
          @select="activeTitleLanguage = $event"
          :missingTranslations="getMissingTranslations(contentData.title)"
          class="mb-2" />
        <v-row dense class="mt-2">
          <v-col cols="12" md="12">
            <v-text-field
              v-model="contentData.title[activeTitleLanguage]"
              :rules="[v => !!v || requiredMessage]"
              autocomplete="off"
              class="font-weight-bold"
              clearable
              label="Naslov"
              required />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end">
          <v-btn
            @click="translate('title')"
            :disabled="isDisabledTitleTranslation"
            :loading="isLoadingTitleTranslation"
            class="mb-4"
            text>
            Dohvati prijevod za {{ activeTitleLanguageFull }}
            <v-icon right>mdi-web</v-icon>
          </v-btn>
        </v-row>
        <v-row dense class="mt-4">
          <v-col>
            <translation-tabs
              @select="activeContentLanguage = $event"
              :missingTranslations="getMissingTranslations(contentData.content)" />
            <text-editor
              v-model="contentData.content[activeContentLanguage]"
              :entityId="contentId"
              placeholder="Unesi sadržaj"
              has-advanced-toolbar />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end">
          <v-btn
            @click="translate('content')"
            :disabled="isDisabledContentTranslation"
            :loading="isLoadingContentTranslation"
            class="mb-4"
            text>
            Dohvati prijevod za {{ activeContentLanguageFull }}
            <v-icon right>mdi-web</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <cover-photo-upload
              v-model="contentData.coverPhoto"
              @delete="contentData.coverPhoto = null"
              @uploaded="contentData.coverPhoto = $event"
              :label="'Naslovna slika'"
              :contentId="contentId" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center justify-end">
            <span v-if="contentData" class="caption mr-4 mb-1 grey--text">
              Zadnje promjene: {{ new Date(contentData.updatedAt) | formatDate }}
            </span>
            <v-btn
              :disabled="isDisabledSave"
              class="px-10"
              color="success"
              @click="save"
              large>
              Spremi promjene
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import AppHeader from '../common/Header';
import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';
import CoverPhotoUpload from './CoverPhotoUpload';
import isEqual from 'lodash/isEqual';
import language from 'common/language';
import propertyApi from '../../api/property';
import pull from 'lodash/pull';
import reduce from 'lodash/reduce';
import TextEditor from '../common/TextEditor';
import TranslationTabs from '../common/TranslationTabs';
import Vue from 'vue';

const processContent = content => {
  const processedContent = {};
  Object.keys(language).forEach(lang => {
    processedContent[lang] = content[lang] || '';
  });
  return processedContent;
}

export default {
  name: 'page-edit-page',
  props: {
    contentId: { type: Number, required: true }
  },
  data: () => ({
    activeTitleLanguage: 'HR',
    activeContentLanguage: 'HR',
    contentData: null,
    initialContentData: { title: {}, content: {} },
    isLoadingContentTranslation: false,
    isLoadingTitleTranslation: false,
    search: '',
    showDialog: false,
    valid: true
  }),
  computed: {
    activeContentLanguageFull() {
      return language[this.activeContentLanguage];
    },
    activeTitleLanguageFull() {
      return language[this.activeTitleLanguage];
    },
    requiredMessage() {
      return 'Ovo polje je obavezno';
    },
    hasChanged() {
      return !isEqual(this.contentData, this.initialContentData);
    },
    isDisabledSave() {
      return !this.valid || !this.hasChanged;
    },
    isDisabledContentTranslation() {
      return this.activeContentLanguage === 'HR' ||
        !!this.contentData.content[this.activeContentLanguage]
    },
    isDisabledTitleTranslation() {
      return this.activeTitleLanguage === 'HR' ||
        !!this.contentData.title[this.activeTitleLanguage]
    },
  },
  methods: {
    ...mapActions('pages', ['get', 'update']),
    ...mapMutations('pages', ['resetPageContent']),
    async getContent() {
      this.isLoading = true;
      const post = cloneDeep(await this.get(this.contentId));
      post.content = processContent(post.content);
      this.contentData = cloneDeep(post);
      this.initialContentData = cloneDeep(post);
      this.isLoading = false;
    },
    getMissingTranslations(field) {
      const languages = Object.keys(language);
      const translations = reduce(field, (acc, value, key) => {
        if (value) acc.push(key)
        return acc;
      }, []);
      const missing = pull(languages, ...translations);
      return missing;
    },
    async save() {
      this.$refs.form.validate();
      this.$nextTick(async () => {
        if (!this.valid) return;
        this.updatedData = await this.update(this.contentData);
        this.contentData = cloneDeep(this.updatedData);
        this.initialContentData = cloneDeep(this.updatedData);
        this.$emit('input', false)
      });
    },
    async translate(field) {
      const capitalized = capitalize(field);
      this[`isLoading${capitalized}Translation`] = true;
      const lang = this[`active${capitalized}Language`] === 'SI'
        ? 'sl' : this[`active${capitalized}Language`].toLowerCase();
      const params = {
        Text: this.contentData[field].HR,
        SourceLanguageCode: 'hr',
        TargetLanguageCode: lang
      }
      const { TranslatedText: translation } = await propertyApi.getTranslation(params);
      const activeLang = this[`active${capitalized}Language`];
      Vue.set(this.contentData[field], activeLang, translation);
      this[`isLoading${capitalized}Translation`] = false;
    }
  },
  created() {
    if (this.contentId) this.getContent();
  },
  beforeRouteLeave(_to, _from, next) {
    this.resetPageContent();
    next();
  },
  components: { AppHeader, CoverPhotoUpload, TextEditor, TranslationTabs }

}
</script>

<style lang="scss" scoped>
.page-wrapper {
  background: #fff;
}

::v-deep {
  .ck-content.ck-editor__editable {
    min-height: 500px;
  }
}
</style>
