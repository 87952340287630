import _get from 'lodash/get';
import request from './request';

const BASE_URL = '/client';

const url = {
  client: clientId => `${BASE_URL}/${clientId}`,
  accessToken: `${BASE_URL}/access-token`,
  emailOffer: `${BASE_URL}/offer`,
};

const getData = res => _get(res, 'data', {});

function create(client) {
  return request.post(BASE_URL, client).then(getData);
}

function fetch(params) {
  return request.get(BASE_URL, { params }).then(getData);
}

function fetchClientProperties(clientId) {
  const propertiesUrl = `${url.client(clientId)}/properties`
  return request.get(propertiesUrl).then(getData);
}

function fetchSentOffers(params) {
  return request.get(url.emailOffer, { params }).then(getData);
}

function generateAccessToken(data) {
  return request.post(url.accessToken, data).then(getData);
}

function get(id) {
  return request.get(url.client(id)).then(getData);
}

function removeAccessToken(data) {
  const { propertyClientId, token } = data;
  const accessTokenUrl = `${url.accessToken}/${propertyClientId}/${token}`;
  return request.delete(accessTokenUrl).then(getData);
}

function sendOffer(data) {
  return request.post(url.emailOffer, data).then(getData);
}

function update(client) {
  return request.patch(`${BASE_URL}/${client.id}`, client).then(getData);
}

function updateAccessToken(data) {
  return request.patch(`${url.accessToken}/${data.id}`, data).then(getData);
}

export default {
  create,
  fetch,
  fetchClientProperties,
  fetchSentOffers,
  generateAccessToken,
  get,
  removeAccessToken,
  sendOffer,
  update,
  updateAccessToken
};
