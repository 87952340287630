import category from 'common/property/category';
import condition from 'common/property/condition';
import infrastructureItems from 'common/property/infrastructure';
import map from 'lodash/map';
import orientation from 'common/property/orientation';
import propertyType from 'common/property/type';
import sortBy from 'lodash/sortBy';

const getItems = (items, lang) => {
  return map(Object.values(items), ({ label, value }) => {
    return { label: label[lang], value };
  })
};

const getSortedItems = (items, lang, key = 'label') => {
  return sortBy(getItems(items, lang), [key]);
}

export const categories = ({ lang }) => {
  return getItems(category, lang);
};

export const conditions = ({ lang }) => {
  return getSortedItems(condition, lang, 'value');
};

export const commercial = ({ lang }) => {
  return getSortedItems(propertyType.commercial, lang);
};

export const infrastructure = ({ lang }) => {
  return getSortedItems(infrastructureItems, lang);
};

export const orientations = ({ lang }) => {
  return getSortedItems(orientation, lang);
};

export const residential = ({ lang }) => {
  return getSortedItems(propertyType.residential, lang);
};
