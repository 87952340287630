<template>
  <v-dialog
    @click:outside="close"
    @keydown.esc="close"
    v-model="show"
    max-width="900px"
    scrollable>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-card class="pb-2">
        <div class="d-flex justify-end pr-3 pt-3">
          <v-icon @click="close" class="close-icon" size="30">
            mdi-close-circle-outline
          </v-icon>
        </div>
        <div v-if="isLoading" class="d-flex justify-center my-12">
          <v-progress-circular :width="4" color="primary" indeterminate />
        </div>
        <template v-else>
          <v-card-title>
            <span class="headline">
              {{ partnerId ? 'Uredi podatke o partneru' : 'Novi partner' }}
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-card class="px-4 pb-4 mb-2 mt-4 mx-4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="partnerData.name"
                    label="Naziv" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="partnerData.email"
                    :rules="[v => !!v || requiredMessage]"
                    label="Email" />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="partnerData.phone"
                    label="Telefon" />
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mt-2 mb-2">
            <v-spacer></v-spacer>
            <v-btn @click="$emit('input', false)">
              Poništi
            </v-btn>
            <v-btn @click="save" :disabled="!valid" color="success">
              {{ partnerId ? 'Spremi promjene' : 'Dodaj novog partnera' }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import api from '../../api/partner';
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'partner-dialog',
  props: {
    partner: { type: Object, default: null },
    partnerId: { type: Number, default: null },
    value: { type: Boolean, default: false }
  },
  data() {
    return {
      partnerData: {
        email: null,
        name: null,
        phone: null
      },
      isLoading: false,
      show: false,
      valid: true
    };
  },
  computed: {
    requiredMessage() {
      return 'Ovo polje je obavezno';
    }
  },
  methods: {
    ...mapActions('partners', ['create', 'update']),
    close() {
      this.$emit('input', false);
    },
    async getPartner() {
      this.isLoading = true;
      const partner = await api.get(this.partnerId);
      this.partnerData = cloneDeep(partner);
      this.initialPartnerData = cloneDeep(partner);
      this.isLoading = false;
    },
    async save() {
      this.$refs.form.validate();
      this.$nextTick(async () => {
        if (!this.valid) return;
        const save = this.partnerId ? this.update : this.create;
        await save(this.partnerData);
        this.$emit('input', false)
      });
    }
  },
  created() {
    if (this.partnerId) this.getPartner();
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.show = this.value;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-wrapper  {
  background-color: #ECEFF1;
}
</style>
