<template>
  <v-text-field
    @input="$emit('input', $event)"
    :value="value"
    :label="label"
    append-icon="mdi-magnify"
    background-color="#d9e1ec"
    clearable
    dense
    filled
    rounded />
</template>

<script>
export default {
  name: 'search-input',
  props: {
    label: { type: String, default: 'Pronađi' },
    value: { type: String, default: '' },
  }
};
</script>
