<template>
  <div>
    <edit-tag-dialog
      v-if="showTagDialog"
      @input="getPropertyTags"
      v-model="showTagDialog"
      :tagId="tagId" />
    <v-row>
      <v-col>
        <v-combobox
          v-model="newTags"
          :items="tags"
          :rules="[v => !!v || 'Ovo polje je obavezno']"
          chips
          deletable-chips
          clearable
          multiple
          label="Unesi tagove na hrvatskom jeziku" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex align-center justify-end">
        <v-btn @click="save" :disabled="!newTags.length" color="success">
          Dodaj tagove
        </v-btn>
      </v-col>
    </v-row>
    <div class="mt-4">
      <draggable v-model="propertyTags" @end="savePositions">
        <v-row v-for="({ tag }, index) in propertyTags" :key="tag.id">
          <v-col>
            <tag-item
              @deletePropertyTag="deletePropertyTag"
              @editTag="editTag"
              :index="index"
              :tag="tag" />
            <v-divider class="mt-2" /> 
          </v-col>
        </v-row>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import api from '../../../../api/tag';
import Draggable from 'vuedraggable';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import EditTagDialog from './EditTagDialog';
import TagItem from './TagItem';

export default {
  name: 'property-tags',
  props: {
    propertyId: { type: Number, required: true }
  },
  data: () => ({
    newTags: [],
    propertyTags: [],
    showTagDialog: false,
    tagId: null
  }),
  computed: {
    ...mapState('tags', { allTags: 'items' }),
    tags() {
      return this.allTags.map(({ name }) => name.HR)
    }
  },
  methods: {
    ...mapActions('tags', ['create', 'fetch']),
    editTag(tagId) {
      this.showTagDialog = true;
      this.tagId = tagId;
    },
    async getPropertyTags() {
      const propertyTags = await api.getPropertyTags(this.propertyId);
      this.propertyTags = orderBy(propertyTags, ['position']);
    },
    async save() {
      let lastTagPosition = this.propertyTags.length - 1;
      const tags = this.newTags.map(tag => {
        const sortedTag = { position: lastTagPosition + 1, name: tag.trim() };
        lastTagPosition++;
        return sortedTag;
      });
      await api.createAndAddTagsToProperty({ propertyId: this.propertyId, tags });
      this.newTags = [];
      this.fetchInitialData();
    },
    savePositions() {
      api.savePropertyTagPositions({ propertyTags: this.propertyTags });
    },
    async deletePropertyTag(tagId, index) {
      await api.deletePropertyTag({ propertyId: this.propertyId, tagId });
      this.propertyTags.splice(index, 1);
      this.fetchInitialData();
    },
    async fetchInitialData() {
      await this.getPropertyTags();
      const excludedIds = map(this.propertyTags, 'tagId');
      this.fetch(excludedIds);
    }
  },
  async created() {
    this.fetchInitialData();
  },
  components: { EditTagDialog, Draggable, TagItem }
}
</script>
