import _get from 'lodash/get';
import request from './request';

const BASE_URL = '/tag';

const url = {
  tag: tagId => `${BASE_URL}/${tagId}`,
  createAndAddTagsToProperty: `${BASE_URL}/create-and-add-tags-to-property`,
  deletePropertyTag: `${BASE_URL}/delete-property-tag`,
  getPropertyTags: `${BASE_URL}/property-tags`,
  savePropertyTagPositions: `${BASE_URL}/save-property-tag-positons`
};

const getData = res => _get(res, 'data', {});

function create(tag) {
  return request.post(BASE_URL, tag).then(getData);
}

function createAndAddTagsToProperty(params) {
  return request.post(url.createAndAddTagsToProperty, params)
    .then(getData);
}

function deletePropertyTag({ propertyId, tagId }) {
  return request.post(url.deletePropertyTag, { propertyId, tagId }).then(getData);
}

function fetch(excludedIds) {
  const params = { excludedIds };
  return request.get(BASE_URL, { params }).then(getData);
}

function getPropertyTags(propertyId) {
  return request.get(url.getPropertyTags, { params: { propertyId } }).then(getData);
}

function get(id) {
  return request.get(url.tag(id)).then(getData);
}

function savePropertyTagPositions(params) {
  return request.post(url.savePropertyTagPositions, params)
    .then(getData);
}

function update(tag) {
  return request.patch(`${BASE_URL}/${tag.id}`, tag).then(getData);
}

export default {
  create,
  createAndAddTagsToProperty,
  deletePropertyTag,
  fetch,
  get,
  getPropertyTags,
  update,
  savePropertyTagPositions
};
