import _get from 'lodash/get';
import request from './request';

const BASE_URL = '/property';

const url = {
  claim: propertyId => `${BASE_URL}/${propertyId}/claim`,
  claimSituation: (propertyId, claimId) => {
    return `${BASE_URL}/${propertyId}/claim/${claimId}/claim-situation`
  },
  filter: `${BASE_URL}/filters`,
  previewToken: `${BASE_URL}/preview-token`,
  property: propertyId => `${BASE_URL}/${propertyId}`,
  propertyClient: propertyId => `${BASE_URL}/${propertyId}/client`,
  translator: `${BASE_URL}/translate`,
  uploadDocument: `${BASE_URL}/upload-document`,
};

const getData = res => _get(res, 'data', {});

function addClients(propertyId, propertyClients) {
  return request.post(url.propertyClient(propertyId), propertyClients)
    .then(getData);
}

function create(property) {
  return request.post(BASE_URL, property).then(getData);
}

function createClaim(propertyId, claim) {
  return request.post(url.claim(propertyId), claim).then(getData);
}

function createClaimSituation(propertyId, claimId, claimSituation) {
  return request.post(url.claimSituation(propertyId, claimId), claimSituation)
    .then(getData);
}

function fetch(params) {
  return request.get(BASE_URL, { params }).then(getData);
}

function generatePreviewToken(data) {
  return request.post(url.previewToken, data).then(getData);
}

function get(id) {
  return request.get(url.property(id)).then(getData);
}

function getFilters() {
  return request.get(url.filter).then(getData);
}

function getTranslation(params) {
  return request.post(url.translator, params).then(getData);
}

function remove(propertyId) {
  return request.delete(url.property(propertyId)).then(getData);
}

function removeClaim(propertyId, claimId) {
  const claimUrl = url.claim(propertyId)
  return request.delete(`${claimUrl}/${claimId}`).then(getData);
}

function removeClaimSituation(propertyId, claimId, claimSituationId) {
  const claimSituationUrl = url.claimSituation(propertyId, claimId)
  return request.delete(`${claimSituationUrl}/${claimSituationId}`)
    .then(getData);
}

function removePropertyClient(propertyId, propertyClientId) {
  const propertyClientUrl = url.propertyClient(propertyId, propertyClientId)
  return request.delete(`${propertyClientUrl}/${propertyClientId}`)
    .then(getData);
}

function update(property) {
  return request.patch(`${BASE_URL}/${property.id}`, property).then(getData);
}

function updateClaim(propertyId, claim) {
  return request.patch(url.claim(propertyId), claim).then(getData);
}

function updateClaimSituation(propertyId, claimId, claimSituation) {
  return request.patch(url.claimSituation(propertyId, claimId), claimSituation)
    .then(getData);
}

function updatePropertyClient(propertyId, propertyClient) {
  const propertyClientUrl = `${url.propertyClient(propertyId)}/${propertyClient.id}`;
  return request.patch(propertyClientUrl, propertyClient).then(getData);
}

function uploadDocument(data) {
  return request.post(url.uploadDocument, data).then(getData);
}

export default {
  addClients,
  create,
  createClaim,
  createClaimSituation,
  fetch,
  generatePreviewToken,
  get,
  getFilters,
  getTranslation,
  remove,
  removeClaim,
  removeClaimSituation,
  removePropertyClient,
  update,
  updateClaim,
  updateClaimSituation,
  updatePropertyClient,
  uploadDocument
};
