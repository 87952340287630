<template>
  <v-dialog
    v-model="show"
    @click:outside="close"
    @keydown.esc="close"
    class="map-dialog"
    max-width="1760px"
    scrollable>
    <v-card class="pb-2">
      <v-card-title>
        <span class="headline">Označavanje lokacije</span>
      </v-card-title>
      <v-divider class="mb-6"></v-divider>
      <v-card-text>
        <google-map
          @updateCoordinates="coordinates = $event"
          :initialMarkerPosition="initialMarkerPosition" />
      </v-card-text>
      <v-divider class="mt-6"></v-divider>
      <v-card-actions class="mt-2 mb-2">
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)">
          Zatvori
        </v-btn>
        <v-btn @click="confirm" :disabled="!coordinates" color="success">
          Potvrdi
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GoogleMap from './GoogleMap.vue';

export default {
  name: 'google-map-dialog',
  props: {
    initialMarkerPosition: { type: Object, default: null },
    value: { type: Boolean, default: false }
  },
  data() {
    return {
      coordinates: null,
      show: false,
    };
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    confirm() {
      this.$emit('updateCoordinates', this.coordinates);
      this.$emit('input', false);
    }
  },
  watch: {
    value:{
      immediate: true,
      handler() {
        this.show = this.value;
      }
    }
  },
  components: { GoogleMap }
}
</script>
