<template>
  <div class="app-bar-wrapper">
    <v-app-bar 
      :fixed="isFixed"
      class="app-header py-0"
      height="auto">
      <v-container>
        <slot></slot>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'app-header',
  data: () => ({
    scrollY: 0
  }),
  computed: {
    isFixed() {
      return this.$vuetify.breakpoint.lgAndUp && this.scrollY > 76;
    }
  },
  methods: {
    onScroll() {
      this.scrollY = window.scrollY;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
}
</script>

<style lang="scss">
.app-header {
  background-color: #fff !important;
  min-height: 128px;
}

.app-bar-wrapper {
  min-height: 128px;

  .v-app-bar {
    margin-top: 1rem !important;
    box-shadow: 0 0 0 !important;
  }

  .v-app-bar--fixed {
    z-index: 4 !important;
    box-shadow: 0 0px 10px -4px grey !important;
    margin-top: 60px !important;
  }

  .v-toolbar__content {
    min-height: 128px;
  }
}
</style>
