<template>
  <div>
    Not found
  </div>
</template>

<script>
export default {
  name: 'not-found'
}
</script>
