<template>
  <div>
    <div
      v-if="isLoading"
      class="loader-wrapper d-flex justify-center align-center">
      <v-progress-circular color="primary" indeterminate size="30" />
    </div>
    <template v-else>
      <app-header>
        <v-row>
          <v-col cols="12" md="6">
            <h1>{{ title }}</h1>
            <span v-if="property">ID: {{ property.publicId }}</span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex justify-end">
            <v-tooltip v-if="property" top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="openPreviewLink"
                  class="mr-2"
                  color="primary"
                  fab>
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Pogledaj izgled stranice</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </app-header>
      <v-container>
        <v-tabs centered show-arrows>
          <v-tab :to="getRouteLink('edit-property')" exact>
            <v-icon left>mdi-home</v-icon>
            Nekretnina
          </v-tab>
          <v-tab
            v-if="!hasClaim"
            @click="createBlankClaim"
            :disabled="!propertyId"
            class="accent-tab wide">
            <v-progress-circular
              v-if="isClaimSaving"
              color="primary"
              indeterminate
              size="16"
              width="2" />
            <template v-else>
              <v-icon left>mdi-plus</v-icon>
              Tražbina
            </template>
          </v-tab>
          <v-tab v-else :to="getRouteLink('edit-property-claim')">
            <v-icon left>mdi-cash-multiple</v-icon>
            Tražbina
          </v-tab>
          <v-tab v-if="propertyId" :to="getRouteLink('edit-property-clients')">
            <v-icon left>mdi-account</v-icon>
            Povezani Klijenti
          </v-tab>
          <v-tab v-if="propertyId" :to="getRouteLink('edit-property-tags')">
            <v-icon left>mdi-tag-outline</v-icon>
            Tagovi
          </v-tab>
        </v-tabs>
        <div class="form-wrapper pa-4 mt-4">
          <router-view :key="$route.name" />
        </div>
      </v-container>
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import api from '../../api/property';
import AppHeader from '../common/Header';
import claimType from 'common//property/claimType';
import get from 'lodash/get';
import language from 'common/language';
import Promise from 'bluebird';

const processTranslations = field => {
  const processedField = {};
  Object.keys(language).forEach(lang => {
    processedField[lang] = field[lang] || '';
  });
  return processedField;
}

export default {
  name: 'property-form-page',
  props: {
    propertyId: { type: Number, default: null }
  },
  data: () => ({
    isClaimSaving: false
  }),
  computed: {
    ...mapState('properties', ['isLoading', 'property']),
    hasClaim() {
      return get(this.property, 'claim.id', false);
    },
    title() {
      return this.property ? this.property.title.HR : 'Nova nekretnina'
    }
  },
  methods: {
    ...mapActions('properties', ['createClaim', 'get']),
    ...mapMutations('properties', ['setProperty']),
    async createBlankClaim() {
      this.isClaimSaving = true;
      const claimData = {
        propertyId: this.property.id,
        situation: processTranslations({}),
        claimType: claimType.BANKRUPTCY.value
      };
      await Promise.delay(1000).then(async () => {
        await this.createClaim(claimData);
        this.isClaimSaving = false;
        this.$router.push(this.getRouteLink('edit-property-claim'));
      });
    },
    openPreviewLink() {
      const windowReference = window.open(); // Safari popup blocker fix
      const data = { propertyId: this.property.id };
      api.generatePreviewToken(data).then(({ token }) => {
        const url = `${process.env.PORTAL_URL}/hr/property/${this.propertyId}/preview/${token}`;
        windowReference.location = url;
      });
    },
    getRouteLink(name) {
      if (!this.property) return null;
      return { name, params: { propertyId: get(this.property, 'id') } };
    }
  },
  watch: {
    propertyId: {
      immediate: true,
      async handler(val) {
        if (!val) return this.setProperty(null);
        await this.get(this.propertyId);
      }
    }
  },
  beforeRouteLeave (_, __, next) {
    this.setProperty(null)
    next();
  },
  components: { AppHeader }
}
</script>

<style lang="scss" scoped>

.form-wrapper {
  background: white;
}

::v-deep .v-item-group.v-tabs-bar {
  background-color: transparent !important;
}

.loader-wrapper {
  min-height: 10rem;
}

.v-tab.accent-tab {
  &.wide {
    width: 8.875rem;
  }

  &, .v-icon {
    color: #E65100 !important;
  }
}
</style>
