import { format, formatDistanceStrict, formatRelative } from 'date-fns';
import { enGB, hr } from 'date-fns/locale'
import numeral from 'numeral';

const dateFormatLong = 'dd.MM.yyyy. HH:mm';

const distanceFormatDate = (date, lang = 'hr') => {
  const locale = lang === 'en' ? enGB : hr;
  return formatDistanceStrict(new Date(date), new Date(), { locale });
};

const formatCurrency = value => {
  return `${formatNumber(value)} €`;
};
const formatNumber = value => {
  return `${numeral(value).format('0,0')}`;
};

const formatDate = (date, dateFormat = dateFormatLong) => format(date, dateFormat);

const relativeFormatDate = date => {
  return formatRelative(new Date(date), new Date(), { locale: hr });
};

export {
  distanceFormatDate, formatCurrency, formatDate, formatNumber, relativeFormatDate
};
