<template>
  <v-container class="px-0">
    <v-row>
      <v-col cols="12" md="9">
        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header class="justify-start">
              <div class="d-flex justify-space-between">
                <span>Filteri</span>
                <span class="active-filters grey--text mr-2">
                  Odabrano filtera: {{ activeFiltersCount }}
                </span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filters.selectedAuthor"
                    :items="authors"
                    clearable
                    item-text="label"
                    item-value="value"
                    label="Autor"
                    multiple />
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filters.selectedPartner"
                    :items="partners"
                    clearable
                    item-text="label"
                    item-value="value"
                    label="Partner"
                    multiple />
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filters.selectedAgent"
                    :items="agents"
                    clearable
                    item-text="label"
                    item-value="value"
                    label="Agent"
                    multiple />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filters.selectedCategory"
                    @input="filters.selectedPropertyType=[]"
                    :items="categories"
                    clearable
                    item-text="label"
                    label="Kategorija"
                    multiple>
                    <template v-slot:selection="{ item, index }">
                      <selection-slot
                        :index="index"
                        :label="item.label"
                        :totalItems="filters.selectedCategory.length" />
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filters.selectedPropertyType"
                    :disabled="!filters.selectedCategory.length"
                    :items="propertyTypes"
                    clearable
                    item-text="label"
                    label="Tip nekretnine"
                    multiple>
                    <template v-slot:selection="{ item, index }">
                      <selection-slot
                        :index="index"
                        :label="item.label"
                        :totalItems="filters.selectedPropertyType.length" />
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filters.selectedItemType"
                    :items="itemTypes"
                    clearable
                    item-text="label"
                    label="Nekretnine/Tražbine"
                    multiple />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filters.selectedRegion"
                    @input="onRegionChange"
                    :items="regions"
                    clearable
                    item-text="name"
                    item-value="id"
                    label="Regija" />
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filters.selectedIsland"
                    :items="islands"
                    clearable
                    item-text="name"
                    item-value="id"
                    label="Otok" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filters.selectedCounty"
                    @input="onCountyChange"
                    :items="counties"
                    clearable
                    item-text="name"
                    item-value="id"
                    label="Županija" />
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filters.selectedMunicipality"
                    @input="onMunicipalityChange"
                    :disabled="!filters.selectedCounty"
                    :items="municipalities"
                    clearable
                    item-text="name"
                    item-value="id"
                    label="Općina" />
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filters.selectedCity"
                    :disabled="!filters.selectedMunicipality"
                    :items="cities"
                    clearable
                    item-text="name"
                    item-value="id"
                    label="Mjesto" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filters.selectedPublished"
                    :items="booleanValues"
                    clearable
                    item-text="label"
                    label="Objavljeno"
                    multiple />
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filters.selectedFeatured"
                    :items="booleanValues"
                    clearable
                    item-text="label"
                    label="Istaknuto"
                    multiple />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn @click="resetFilters">
                    <v-icon class="mr-2">mdi-close</v-icon> Poništi sve filtere
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="3">
        <div class="d-flex align-stretch">
          <div class="select-wrapper">
            <v-select
              v-model="filters.selectedSortingField"
              :items="sortingOptions"
              item-text="label"
              item-value="value"
              label="Sortiraj po" />
          </div>
          <v-icon
            :style="arrowStyle"
            @click="toggleSortingDirection"
            size="18"
            class="arrow-icon ml-4">
            mdi-arrow-down
          </v-icon>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import api from '../../api/property';
import capitalize from 'lodash/capitalize';
import category from 'common/property/category';
import each from 'lodash/isEmpty';
import isEmpty from 'lodash/isEmpty';
import locationApi from '../../api/location';
import orderBy from 'lodash/orderBy';
import SelectionSlot from '../common/MultipleSelectionSlot.vue';
import toLower from 'lodash/toLower';
import without from 'lodash/without';

const sortingOptions = [
  { label: 'Abecedno (po naslovu)', value: 'title' },
  { label: 'Cijena', value: 'price' },
  { label: 'Datum kreiranja', value: 'createdAt' },
  { label: 'Datum uređivanja', value: 'updatedAt' }
];

const mapOptionItem = ({ id, name }) => {
  return { id, name: capitalize(toLower(name)) };
};

export default {
  data: () => ({
    agents: [{ label: 'Nedodijeljeno', value: 'UNASSIGNED' }],
    authors: [],
    cities: [],
    counties: [],
    filters: {
      selectedAgent: [],
      selectedAuthor: [],
      selectedCategory: [],
      selectedCity: null,
      selectedCounty: null,
      selectedFeatured: [],
      selectedIsland: [],
      selectedItemType: [],
      selectedMunicipality: null,
      selectedPartner: [],
      selectedPropertyType: [],
      selectedPublished: [],
      selectedRegion: [],
      selectedSortingField: 'createdAt',
      selectedSortingDirection: 'desc',
    },
    islands: [],
    municipalities: [],
    partners: [],
    regions: [],
    sortingOptions,
    turn: 0
  }),
  computed: {
    ...mapGetters('translations', ['categories','commercial','residential']),
    ...mapState('translations', ['lang']),
    activeFilters() {
      const filters = {};
      Object.keys(this.filters).forEach(key => {
        const isArray = Array.isArray(this.filters[key]);
        const isNotEmpty = !isEmpty(this.filters[key]);
        const hasValue = !!this.filters[key];
        if ((isArray && isNotEmpty) || (!isArray && hasValue)) {
          filters[key] = this.filters[key];
        }
      });
      return filters;
    },
    activeFiltersCount() {
      const omittedFields = ['selectedSortingField', 'selectedSortingDirection'];
      const activeKeys = Object.keys(this.activeFilters);
      return without(activeKeys, ...omittedFields).length;
    },
    arrowStyle () {
      return { transform: 'rotate(' + this.turn + 'turn)'}
    },
    itemTypes() {
      return [
        { label: 'Nekretnina', value: 'property' },
        { label: 'Tražbina', value: 'claim' }
      ];
    },
    booleanValues() {
      return [
        { label: 'Da', value: true },
        { label: 'Ne', value: false }
      ];
    },
    propertyTypes() {
      if (!this.filters.selectedCategory.length) return [];
      if (this.filters.selectedCategory.length === 2) {
        return [...this.commercial, ...this.residential];
      }
      return this.filters.selectedCategory.includes(category.COMMERCIAL.value)
        ? this.commercial
        : this.residential
    }
  },
  methods: {
    async fetchCities(municipalityId) {
      this.cities = [];
      if (!municipalityId) return;
      const cities = (await locationApi.getCities(municipalityId)).map(mapOptionItem);
      this.cities = orderBy(cities, ['name']);
    },
    async fetchCounties() {
      this.counties = [];
      const counties = (await locationApi.getCounties(this.filters.selectedRegion))
        .map(mapOptionItem);
      this.counties = orderBy(counties, ['name']);
    },
    async fetchIslands() {
      const islands = (await locationApi.getIslands()).map(mapOptionItem);
      this.islands = orderBy(islands, ['name']);
    },
    async fetchMunicipalities(countyId) {
      if (!countyId) return;
      const municipalities = (await locationApi.getMunicipalities(countyId))
        .map(mapOptionItem);
      this.municipalities = orderBy(municipalities, ['name']);
    },
    async fetchRegions() {
      const regions = (await locationApi.getRegions()).map(mapOptionItem);
      this.regions = orderBy(regions, ['name']);
    },
    async getFilters() {
      const { agents, partners, users } = await api.getFilters();
      this.agents.push(...agents);
      this.authors = users;
      this.partners = partners;
    },
    async onCountyChange() {
      await this.fetchMunicipalities(this.filters.selectedCounty);
      this.resetLocationFields(['selectedMunicipality', 'selectedCity']);
    },
    async onMunicipalityChange() {
      await this.fetchCities(this.filters.selectedMunicipality);
      this.resetLocationFields(['selectedCity']);
    },
    async onRegionChange() {
      await this.fetchCounties();
      this.resetLocationFields([
        'selectedCounty', 'selectedMunicipality', 'selectedCity'
      ]);
    },
    resetFilters() {
      this.filters.selectedAgent = [];
      this.filters.selectedAuthor = [];
      this.filters.selectedCategory = [];
      this.filters.selectedCity = null;
      this.filters.selectedCounty = null;
      this.filters.selectedFeatured = [];
      this.filters.selectedIsland = [];
      this.filters.selectedItemType = [];
      this.filters.selectedMunicipality = null;
      this.filters.selectedPartner = [];
      this.filters.selectedPropertyType = [];
      this.filters.selectedPublished = [];
      this.filters.selectedRegion = [];
    },
    resetLocationFields(fields) {
      each(fields, key => this.filters[key] = null);
    },
    toggleSortingDirection() {
      if (this.filters.selectedSortingDirection === 'desc') {
        this.turn = 0.5;
        return this.filters.selectedSortingDirection = 'asc';
      }
      this.turn = 0;
      this.filters.selectedSortingDirection = 'desc';
    },
  },
  created() {
    this.getFilters();
    this.fetchCounties();
    this.fetchIslands();
    this.fetchRegions();
  },
  watch: {
    'filters.selectedSortingField'(currentValue, previousValue) {
      const { selectedSortingDirection } = this.filters;
      if (currentValue === 'title' && selectedSortingDirection === 'desc') {
        this.toggleSortingDirection();
      }
      if (previousValue === 'title' && selectedSortingDirection === 'asc') {
        this.toggleSortingDirection();
      }
    },
    filters: {
      deep: true,
      handler() {
        this.$emit('input', this.activeFilters);
      }
    }
  },
  components: { SelectionSlot }
}
</script>

<style lang="scss" scoped>
.select-wrapper {
  flex: 1;
}

.label-font {
  font-size: 14px;
}

::v-deep {
  .v-label, .v-select__selections {
    font-size: 14px !important;
  }

  .v-input__icon--clear .v-icon {
    font-size: 18px !important;
  }

  .v-expansion-panel-header {
    .active-filters {
      font-size: 14px;
    }
  }
}

.v-icon.arrow-icon {
  &::before {
    cursor: pointer;
  }

  &::after {
    background-color: transparent !important;
  }
}
</style>
