// import ForgotPassword from './components/auth/ForgotPassword';
import AccessTokenViews from './components/AccessTokenViews';
import Agents from './components/Agents';
import Blog from './components/Blog';
import Clients from './components/Clients';
import EditBlogPost from './components/Blog/Edit';
import EditPage from './components/Pages/Edit';
import get from 'lodash/get';
import Login from './components/auth/Login'
import Main from './components';
import NotFound from './components/common/NotFound';
// import ResetPassword from './components/auth/ResetPassword';
import Partners from './components/Partners';
import Home from './components/Home';
import Pages from './components/Pages';
import Property from './components/Property';
import PropertyClaimForm from './components/Property/Form/Claim';
import PropertyClientsForm from './components/Property/Form/Clients';
import PropertyTagsForm from './components/Property/Form/Tag';
import PropertyForm from './components/Property/Form/Property';
import Router from 'vue-router';
import store from './store';
import transform from 'lodash/transform';
import Vue from 'vue';

Vue.use(Router);

const parseParams = ({ params }) => {
  return transform(params, (acc, val, key) => (acc[key] = parseInt(val, 10)), {});
};
// Handle 404
const fallbackRoute = { path: '*', component: NotFound, name: 'not-found' };

const router = new Router({
  // base: 'mls',
  mode: 'history',
  routes: [
  { path: '/login', name: 'Login', component: Login },
  {
    path: '/',
    component: Main,
    beforeEnter: requireAuth,
    meta: { auth: true },
    children: [{
      path: '',
      name: 'home',
      component: Home,
      props: parseParams
    }, {
      path: '/properties',
      component: Property,
      props: parseParams,
      children: [{
        path: 'add',
        name: 'add-property',
        component: PropertyForm,
        props: parseParams
      }, {
        path: 'edit/:propertyId',
        name: 'edit-property',
        component: PropertyForm,
        props: parseParams
      }, {
        path: 'edit/:propertyId/clients',
        name: 'edit-property-clients',
        component: PropertyClientsForm,
        props: parseParams
      }, {
        path: 'edit/:propertyId/claim',
        name: 'edit-property-claim',
        component: PropertyClaimForm,
        props: parseParams
      }, {
        path: 'edit/:propertyId/tags',
        name: 'edit-property-tags',
        component: PropertyTagsForm,
        props: parseParams
      }]
    }, {
      path: '/clients',
      name: 'clients',
      component: Clients,
      props: parseParams
    }, {
      path: '/clients/:clientId',
      name: 'edit-client',
      component: Clients,
      props: parseParams
    }, {
      path: '/partners',
      name: 'partners',
      component: Partners,
      props: parseParams
    }, {
      path: '/partners/:partnerId',
      name: 'edit-partner',
      component: Partners,
      props: parseParams
    }, {
      path: '/agents',
      name: 'agents',
      component: Agents,
      props: parseParams
    }, {
      path: '/agents/:agentId',
      name: 'edit-agent',
      component: Agents,
      props: parseParams
    }, {
      path: '/property-view',
      name: 'property-view',
      component: AccessTokenViews,
      props: parseParams
    }, {
      path: '/blog',
      name: 'blog',
      component: Blog,
      props: parseParams
    }, {
      path: '/blog/:contentId',
      name: 'edit-blog-post',
      component: EditBlogPost,
      props: parseParams
    }, {
      path: '/pages',
      name: 'pages',
      component: Pages,
      props: parseParams
    }, {
      path: '/pages/:contentId',
      name: 'edit-page',
      component: EditPage,
      props: parseParams
    }]
  },
  fallbackRoute]
});

const isAuthenticated = () => {
  const isAuth = !!get(store.state, 'auth.user');
  return !!isAuth;
}

function requireAuth (to, _, next) {
  return isAuthenticated()
    ? next()
    : next({ path: '/login', query: { redirect: to.fullPath } });
}

export default router;
