import api from '../../../api/client';

const create = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.create(data).then(client => {
    commit('setClient', client);
    commit('setLoadingFlag', false);
    return client;
  });
};

const fetch = ({ commit }, params) => {
  commit('setLoadingFlag', true);
  return api.fetch(params).then((data) => {
    commit('setClients', data);
    commit('setLoadingFlag', false);
    return data;
  });
};

const get = ({ commit }, id) => {
  return api.get(id).then(client => {
    commit('setClient', client);
    return client;
  });
};

const update = ({ commit }, data) => {
  commit('setLoadingFlag', true);
  return api.update(data).then(client => {
    commit('setClient', client);
    commit('setLoadingFlag', false);
    return client;
  });
};

export { create, fetch, get, update };
