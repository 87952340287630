
const setLoadingFlag = (state, flag) => (state.isLoading = flag);

const setAgent = (state, agent) => {
  const index = state.items.map(item => item.id).indexOf(agent.id);
  if (index !== -1) state.items.splice(index, 1);
  state.items.push(agent);
};

const setAgents = (state, agents) => {
  state.items = agents;
};

export {
  setAgent, setAgents, setLoadingFlag
};
