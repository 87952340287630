import get from 'lodash/get';
import request from './request';

const BASE_URL = '/login';

const getData = res => get(res, 'data', {});

function login({ email, password }) {
  return request.post(BASE_URL, { email, password }).then(getData);
}

export default { login };
