<template>
  <div class="text-right mt-4 mr-4">
    {{ title }}
    <v-icon :color="iconColor">mdi-{{ icon }}</v-icon>
  </div>
</template>

<script>
export default {
  name: 'card-title',
  props: {
    icon: { type: String, required: true },
    iconColor: { type: String, default: 'primary' },
    title: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
.v-icon {
  margin-bottom: 0.25rem;
}
</style>
