import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
  blogPost: null,
  items: [],
  isLoading: false
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state
};
