module.exports = {
  PPN: {
    key: 'ppn',
    value: 'PPN',
    label: { HR: 'PPN', EN: 'Regular tax' }
  },
  PDV: {
    key: 'pdv',
    value: 'PDV',
    label: { HR: 'PDV', EN: 'VAT' }
  },
  OPTIONAL: {
    key: 'optional',
    value: 'OPTIONAL',
    label: { HR: 'Opcionalno', EN: 'Optional' }
  }
};
