module.exports = {
  BRUTTO: {
    key: 'brutto',
    value: 'BRUTTO',
    label: { EN: 'Gross developed area ', HR: 'BRP (Brutto razvijena površina)' }
  },
  NETTO: {
    key: 'netto',
    value: 'NETTO',
    label: { EN: 'Net developed area', HR: 'NKP (Netto korisna površina)' }
  }
};
