<template>
  <v-dialog
    v-model="show"
    @click:outside="close"
    @keydown.esc="close"
    max-width="900px"
    scrollable>
    <v-card class="pb-2">
      <v-card-title>
        <span class="headline">Poveži klijenta</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-card class="px-4 pb-4 mb-2 mt-4 mx-4">
          <v-data-table
            v-model="selected"
            :footer-props="{ itemsPerPageOptions: [10, 20, 50, 100] }"
            :options.sync="options"
            :headers="headers"
            :loading="isLoading"
            :items="clients"
            :items-per-page="10"
            :search="search"
            :server-items-length="totalCount"
            show-select>
            <template v-slot:top>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pronađi kupca"
                class="search-field mx-4 pt-6" />
            </template>
            <template v-slot:[`item.propertyPreference.budget`]="{ item }">
              {{ item.propertyPreference.budget | formatCurrency }}
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2 mb-2">
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)">
          Poništi
        </v-btn>
        <v-btn @click="save" :disabled="!selected.length" color="success">
          Poveži klijenta
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import map from 'lodash/map';

export default {
  name: 'property-client-dialog',
  props: {
    client: { type: Object, default: null },
    property: { type: Object, required: true },
    value: { type: Boolean, default:  false }
  },
  data() {
    return {
      options: {},
      search: '',
      selected: [],
      show: false,
      valid: true
    };
  },
  computed: {
    ...mapGetters('clients', ['clients']),
    ...mapState('clients', ['isLoading', 'totalCount']),
    headers() {
      return [
        { text: 'Ime', value: 'fullName' },
        { text: 'Email', value: 'email' },
        { text: 'Mobitel', sortable: false, value: 'phone' },
        { text: 'Budžet', value: 'propertyPreference.budget' }
      ];
    }
  },
  methods: {
    ...mapActions('clients', ['fetch']),
    ...mapActions('properties', ['addClients']),
    close() {
      this.$emit('input', false);
    },
    async save() {
      const propertyClients = this.selected.map(item => ({
        clientId: item.id,
        documents: {
          contactAgreement: null,
          mediationAgreement: null,
          NDA: null,
          proofOfFunds: null
        },
        propertyId: this.property.id
      }));
      const data = { propertyId: this.property.id, propertyClients }
      await this.addClients(data);
      this.$emit('input', false)
    },
    async fetchClients() {
      const { page: currentPage, itemsPerPage } = this.options
      const { search } = this;
      const excludedIds = map(this.property.clients, 'clientId');
      await this.fetch({ excludedIds, currentPage, itemsPerPage, search });
    },
    onSearch: debounce(function () {
      this.options.page = 1;
      return this.fetchClients();
    }, 700)
  },
  created() {
    this.fetchClients();
  },
  watch: {
    value:{
      immediate: true,
      handler() {
        this.show = this.value;
      }
    },
    options: {
      handler () {
        this.fetchClients()
      },
      deep: true,
    },
    search() {
      this.onSearch();
    }
  },
  components: { }
}
</script>
