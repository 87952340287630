
const setLoadingFlag = (state, flag) => (state.isLoading = flag);

const setClient = (state, client) => {
  const index = state.items.map(item => item.id).indexOf(client.id);
  if (index !== -1) state.items.splice(index, 1);
  state.items.push(client);
};

const setClients = (state, { items, count }) => {
  state.items = items;
  state.totalCount = count;
};

export { setClient, setClients, setLoadingFlag };
